export const Hamburger = ({ handlenav }) => {
  return (
    <svg
      onClick={handlenav}
      style={{ cursor: "pointer" }}
      width="41"
      height="41"
      viewBox="0 0 41 41"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M35.875 30.75H5.125V27.3333H35.875V30.75ZM35.875 22.2083H5.125V18.7917H35.875V22.2083ZM35.875 13.6667H5.125V10.25H35.875V13.6667Z"
        fill="#C86550"
      />
    </svg>
  );
};
export const Uploade = () => {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.03125 5.58457H8.33027V11.5313C8.33027 11.6086 8.39355 11.6719 8.4709 11.6719H9.52559C9.60293 11.6719 9.66621 11.6086 9.66621 11.5313V5.58457H10.9688C11.0865 5.58457 11.1516 5.44922 11.0795 5.35781L9.11074 2.86524C9.09759 2.84843 9.08078 2.83483 9.06159 2.82549C9.04241 2.81614 9.02134 2.81128 9 2.81128C8.97866 2.81128 8.95759 2.81614 8.93841 2.82549C8.91922 2.83483 8.90241 2.84843 8.88926 2.86524L6.92051 5.35606C6.84844 5.44922 6.91348 5.58457 7.03125 5.58457ZM15.4336 11.0039H14.3789C14.3016 11.0039 14.2383 11.0672 14.2383 11.1445V13.8516H3.76172V11.1445C3.76172 11.0672 3.69844 11.0039 3.62109 11.0039H2.56641C2.48906 11.0039 2.42578 11.0672 2.42578 11.1445V14.625C2.42578 14.9361 2.67715 15.1875 2.98828 15.1875H15.0117C15.3229 15.1875 15.5742 14.9361 15.5742 14.625V11.1445C15.5742 11.0672 15.5109 11.0039 15.4336 11.0039Z"
        fill="#016CC1"
      />
    </svg>
  );
};
export const Star = () => {
  return (
    <svg
      width="17"
      height="16"
      viewBox="0 0 17 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.59461 0.245117L10.9305 5.27501L16.4361 5.94227L12.3742 9.71818L13.4409 15.1605L8.59461 12.4642L3.74832 15.1605L4.81503 9.71818L0.753149 5.94227L6.2587 5.27501L8.59461 0.245117Z"
        fill="#FFC633"
      />
    </svg>
  );
};
export const Comma = () => {
  return (
    <svg
      width="110"
      height="114"
      viewBox="0 0 110 114"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect y="0.5" width="110" height="113" rx="20" fill="#F6F6F6" />
      <path
        d="M53.9029 39.443C54.4651 37.9801 56.5349 37.9801 57.0971 39.443C58.0011 41.7951 60.9364 42.5816 62.8954 40.9966C64.1137 40.0108 65.9062 41.0457 65.6617 42.5937C65.2685 45.0827 67.4173 47.2315 69.9063 46.8383C71.4543 46.5938 72.4892 48.3863 71.5034 49.6046C69.9184 51.5636 70.7049 54.4989 73.057 55.4029C74.5199 55.9651 74.5199 58.0349 73.057 58.5971C70.7049 59.5011 69.9184 62.4364 71.5034 64.3954C72.4892 65.6137 71.4543 67.4062 69.9063 67.1617C67.4173 66.7685 65.2685 68.9173 65.6617 71.4063C65.9062 72.9543 64.1137 73.9892 62.8954 73.0034C60.9364 71.4184 58.0011 72.2049 57.0971 74.557C56.5349 76.0199 54.4651 76.0199 53.9029 74.557C52.9989 72.2049 50.0636 71.4184 48.1046 73.0034C46.8863 73.9892 45.0938 72.9543 45.3383 71.4063C45.7315 68.9173 43.5827 66.7685 41.0937 67.1617C39.5457 67.4062 38.5108 65.6137 39.4966 64.3954C41.0816 62.4364 40.2951 59.5011 37.943 58.5971C36.4801 58.0349 36.4801 55.9651 37.943 55.4029C40.2951 54.4989 41.0816 51.5636 39.4966 49.6046C38.5108 48.3863 39.5457 46.5938 41.0937 46.8383C43.5827 47.2315 45.7315 45.0827 45.3383 42.5937C45.0938 41.0457 46.8863 40.0108 48.1046 40.9966C50.0636 42.5816 52.9989 41.7951 53.9029 39.443Z"
        stroke="#5956E9"
        stroke-width="2"
      />
      <circle cx="55.5" cy="57" r="11.5" stroke="#5956E9" stroke-width="2" />
    </svg>
  );
};
export const Prev = ({ handleClick }) => {
  return (
    <svg
      onClick={handleClick}
      style={{ cursor: "pointer" }}
      width="76"
      height="77"
      viewBox="0 0 76 77"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="38" cy="38.4727" r="38" fill="white" />
      <path
        d="M17.9393 37.412C17.3536 37.9978 17.3536 38.9475 17.9393 39.5333L27.4853 49.0793C28.0711 49.665 29.0208 49.665 29.6066 49.0793C30.1924 48.4935 30.1924 47.5437 29.6066 46.9579L21.1213 38.4727L29.6066 29.9874C30.1924 29.4016 30.1924 28.4518 29.6066 27.8661C29.0208 27.2803 28.0711 27.2803 27.4853 27.8661L17.9393 37.412ZM58 36.9727L19 36.9727V39.9727L58 39.9727V36.9727Z"
        fill="#F09303"
      />
    </svg>
  );
};

export const Search = () => {
  return (
    <svg
      width="110"
      height="114"
      viewBox="0 0 110 114"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect y="0.5" width="110" height="113" rx="20" fill="#F6F6F6" />
      <path
        d="M53.9029 39.443C54.4651 37.9801 56.5349 37.9801 57.0971 39.443C58.0011 41.7951 60.9364 42.5816 62.8954 40.9966C64.1137 40.0108 65.9062 41.0457 65.6617 42.5937C65.2685 45.0827 67.4173 47.2315 69.9063 46.8383C71.4543 46.5938 72.4892 48.3863 71.5034 49.6046C69.9184 51.5636 70.7049 54.4989 73.057 55.4029C74.5199 55.9651 74.5199 58.0349 73.057 58.5971C70.7049 59.5011 69.9184 62.4364 71.5034 64.3954C72.4892 65.6137 71.4543 67.4062 69.9063 67.1617C67.4173 66.7685 65.2685 68.9173 65.6617 71.4063C65.9062 72.9543 64.1137 73.9892 62.8954 73.0034C60.9364 71.4184 58.0011 72.2049 57.0971 74.557C56.5349 76.0199 54.4651 76.0199 53.9029 74.557C52.9989 72.2049 50.0636 71.4184 48.1046 73.0034C46.8863 73.9892 45.0938 72.9543 45.3383 71.4063C45.7315 68.9173 43.5827 66.7685 41.0937 67.1617C39.5457 67.4062 38.5108 65.6137 39.4966 64.3954C41.0816 62.4364 40.2951 59.5011 37.943 58.5971C36.4801 58.0349 36.4801 55.9651 37.943 55.4029C40.2951 54.4989 41.0816 51.5636 39.4966 49.6046C38.5108 48.3863 39.5457 46.5938 41.0937 46.8383C43.5827 47.2315 45.7315 45.0827 45.3383 42.5937C45.0938 41.0457 46.8863 40.0108 48.1046 40.9966C50.0636 42.5816 52.9989 41.7951 53.9029 39.443Z"
        stroke="#5956E9"
        stroke-width="2"
      />
      <circle cx="55.5" cy="57" r="11.5" stroke="#5956E9" stroke-width="2" />
    </svg>
  );
};
export const Profile = () => {
  return (
    <svg
      width="59"
      height="59"
      viewBox="0 0 59 59"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="29.6299" cy="29.5879" r="29" fill="#D9D9D9" />
    </svg>
  );
};
export const Iconq01 = () => {
  return (
    <svg
      width="41"
      height="40"
      viewBox="0 0 51 50"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="25.7285" cy="25" r="25" fill="black" />
      <path
        d="M25.4885 35.6H25.9685C26.8685 35.6 27.3184 35.6 27.6339 35.3708C27.7357 35.2968 27.8253 35.2072 27.8993 35.1053C28.1285 34.7899 28.1285 34.3399 28.1285 33.44V26.6H29.6285C30.4712 26.6 30.8925 26.6 31.1952 26.3978C31.3262 26.3102 31.4387 26.1977 31.5263 26.0667C31.7285 25.764 31.7285 25.3427 31.7285 24.5C31.7285 23.6573 31.7285 23.236 31.5263 22.9333C31.4387 22.8023 31.3262 22.6898 31.1952 22.6022C30.8925 22.4 30.4712 22.4 29.6285 22.4H28.1285V20C28.1285 19.4409 28.1285 19.1613 28.2199 18.9408C28.3417 18.6467 28.5753 18.4131 28.8693 18.2913C29.0898 18.2 29.3694 18.2 29.9285 18.2C30.4876 18.2 30.7672 18.2 30.9877 18.1087C31.2818 17.9869 31.5154 17.7533 31.6372 17.4592C31.7285 17.2387 31.7285 16.9591 31.7285 16.4V15.8667C31.7285 15.2449 31.7285 14.934 31.6161 14.6929C31.4969 14.4372 31.2913 14.2317 31.0357 14.1124C30.7945 14 30.4836 14 29.8618 14C27.6855 14 26.5974 14 25.7535 14.3935C24.8586 14.8108 24.1393 15.5301 23.722 16.425C23.3285 17.2689 23.3285 18.357 23.3285 20.5333V22.4H21.8285C20.9858 22.4 20.5645 22.4 20.2618 22.6022C20.1308 22.6898 20.0183 22.8023 19.9308 22.9333C19.7285 23.236 19.7285 23.6573 19.7285 24.5C19.7285 25.3427 19.7285 25.764 19.9308 26.0667C20.0183 26.1977 20.1308 26.3102 20.2618 26.3978C20.5645 26.6 20.9858 26.6 21.8285 26.6H23.3285V33.44C23.3285 34.3399 23.3285 34.7899 23.5577 35.1053C23.6317 35.2072 23.7213 35.2968 23.8232 35.3708C24.1386 35.6 24.5886 35.6 25.4885 35.6Z"
        stroke="white"
        stroke-width="2.5"
        stroke-linejoin="round"
      />
    </svg>
  );
};
export const Iconq02 = () => {
  return (
    <svg
      width="41"
      height="40"
      viewBox="0 0 51 50"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="25.7285" cy="25" r="25" fill="black" />
      <path
        d="M33.6551 18.4428C33.6551 18.7297 33.4225 18.9622 33.1357 18.9622C32.8488 18.9622 32.6162 18.7297 32.6162 18.4428C32.6162 18.1559 32.8488 17.9233 33.1357 17.9233C33.4225 17.9233 33.6551 18.1559 33.6551 18.4428Z"
        fill="#292556"
        stroke="white"
        stroke-width="1.5"
      />
      <path
        d="M14.7285 23.14C14.7285 19.3319 14.7285 17.4279 15.6983 16.0931C16.0115 15.6621 16.3906 15.283 16.8217 14.9698C18.1564 14 20.0605 14 23.8685 14H28.4385C32.2466 14 34.1506 14 35.4854 14.9698C35.9165 15.283 36.2956 15.6621 36.6087 16.0931C37.5785 17.4279 37.5785 19.3319 37.5785 23.14V27.71C37.5785 31.5181 37.5785 33.4221 36.6087 34.7569C36.2956 35.1879 35.9165 35.567 35.4854 35.8802C34.1506 36.85 32.2466 36.85 28.4385 36.85H23.8685C20.0605 36.85 18.1564 36.85 16.8217 35.8802C16.3906 35.567 16.0115 35.1879 15.6983 34.7569C14.7285 33.4221 14.7285 31.5181 14.7285 27.71V23.14Z"
        stroke="white"
        stroke-width="1.5"
        stroke-linejoin="round"
      />
      <path
        d="M31.2313 25.425C31.2313 28.2294 28.9579 30.5028 26.1535 30.5028C23.3491 30.5028 21.0757 28.2294 21.0757 25.425C21.0757 22.6206 23.3491 20.3472 26.1535 20.3472C28.9579 20.3472 31.2313 22.6206 31.2313 25.425Z"
        stroke="white"
        stroke-width="1.5"
        stroke-linejoin="round"
      />
    </svg>
  );
};
export const Iconq03 = () => {
  return (
    <svg
      width="41"
      height="40"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_3443_2420)">
        <path
          d="M18.5808 0H5.41922C2.42627 0 0 2.4375 0 5.44431V18.5557C0 21.5625 2.42627 24 5.41922 24H18.5808C21.5737 24 24 21.5625 24 18.5557V5.44431C24 2.4375 21.5737 0 18.5808 0Z"
          fill="black"
        />
        <path
          d="M15.1748 6.98608H16.8679L13.169 11.2338L17.5204 17.0134H14.113L11.4445 13.5083L8.39055 17.0134H6.69648L10.6527 12.4704L6.47852 6.98608H9.97211L12.3843 10.1902L15.1748 6.98608ZM14.5804 15.9953H15.5188L9.46258 7.951H8.4557L14.5804 15.9953Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_3443_2420">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
export const Iconq11 = () => {
  return (
    <svg
      width="26"
      height="25"
      viewBox="0 0 51 50"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="25.7285" cy="25" r="25" fill="#D85D5D" />
      <path
        d="M25.4885 35.6H25.9685C26.8685 35.6 27.3184 35.6 27.6339 35.3708C27.7357 35.2968 27.8253 35.2072 27.8993 35.1053C28.1285 34.7899 28.1285 34.3399 28.1285 33.44V26.6H29.6285C30.4712 26.6 30.8925 26.6 31.1952 26.3978C31.3262 26.3102 31.4387 26.1977 31.5263 26.0667C31.7285 25.764 31.7285 25.3427 31.7285 24.5C31.7285 23.6573 31.7285 23.236 31.5263 22.9333C31.4387 22.8023 31.3262 22.6898 31.1952 22.6022C30.8925 22.4 30.4712 22.4 29.6285 22.4H28.1285V20C28.1285 19.4409 28.1285 19.1613 28.2199 18.9408C28.3417 18.6467 28.5753 18.4131 28.8693 18.2913C29.0898 18.2 29.3694 18.2 29.9285 18.2C30.4876 18.2 30.7672 18.2 30.9877 18.1087C31.2818 17.9869 31.5154 17.7533 31.6372 17.4592C31.7285 17.2387 31.7285 16.9591 31.7285 16.4V15.8667C31.7285 15.2449 31.7285 14.934 31.6161 14.6929C31.4969 14.4372 31.2913 14.2317 31.0357 14.1124C30.7945 14 30.4836 14 29.8618 14C27.6855 14 26.5974 14 25.7535 14.3935C24.8586 14.8108 24.1393 15.5301 23.722 16.425C23.3285 17.2689 23.3285 18.357 23.3285 20.5333V22.4H21.8285C20.9858 22.4 20.5645 22.4 20.2618 22.6022C20.1308 22.6898 20.0183 22.8023 19.9308 22.9333C19.7285 23.236 19.7285 23.6573 19.7285 24.5C19.7285 25.3427 19.7285 25.764 19.9308 26.0667C20.0183 26.1977 20.1308 26.3102 20.2618 26.3978C20.5645 26.6 20.9858 26.6 21.8285 26.6H23.3285V33.44C23.3285 34.3399 23.3285 34.7899 23.5577 35.1053C23.6317 35.2072 23.7213 35.2968 23.8232 35.3708C24.1386 35.6 24.5886 35.6 25.4885 35.6Z"
        stroke="white"
        stroke-width="2.5"
        stroke-linejoin="round"
      />
    </svg>
  );
};
export const Iconq12 = () => {
  return (
    <svg
      width="26"
      height="25"
      viewBox="0 0 51 50"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="25.7285" cy="25" r="25" fill="#D85D5D" />
      <path
        d="M33.6551 18.4428C33.6551 18.7297 33.4225 18.9622 33.1357 18.9622C32.8488 18.9622 32.6162 18.7297 32.6162 18.4428C32.6162 18.1559 32.8488 17.9233 33.1357 17.9233C33.4225 17.9233 33.6551 18.1559 33.6551 18.4428Z"
        fill="#292556"
        stroke="white"
        stroke-width="1.5"
      />
      <path
        d="M14.7285 23.14C14.7285 19.3319 14.7285 17.4279 15.6983 16.0931C16.0115 15.6621 16.3906 15.283 16.8217 14.9698C18.1564 14 20.0605 14 23.8685 14H28.4385C32.2466 14 34.1506 14 35.4854 14.9698C35.9165 15.283 36.2956 15.6621 36.6087 16.0931C37.5785 17.4279 37.5785 19.3319 37.5785 23.14V27.71C37.5785 31.5181 37.5785 33.4221 36.6087 34.7569C36.2956 35.1879 35.9165 35.567 35.4854 35.8802C34.1506 36.85 32.2466 36.85 28.4385 36.85H23.8685C20.0605 36.85 18.1564 36.85 16.8217 35.8802C16.3906 35.567 16.0115 35.1879 15.6983 34.7569C14.7285 33.4221 14.7285 31.5181 14.7285 27.71V23.14Z"
        stroke="white"
        stroke-width="1.5"
        stroke-linejoin="round"
      />
      <path
        d="M31.2313 25.425C31.2313 28.2294 28.9579 30.5028 26.1535 30.5028C23.3491 30.5028 21.0757 28.2294 21.0757 25.425C21.0757 22.6206 23.3491 20.3472 26.1535 20.3472C28.9579 20.3472 31.2313 22.6206 31.2313 25.425Z"
        stroke="white"
        stroke-width="1.5"
        stroke-linejoin="round"
      />
    </svg>
  );
};
export const Iconq13 = () => {
  return (
    <svg
      width="26"
      height="25"
      viewBox="0 0 51 50"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="25.7285" cy="25" r="25" fill="#D85D5D" />
      <path
        d="M24.8845 23.5208V31.1374V27.8845C24.8845 25.4745 26.8382 23.5208 29.2482 23.5208V23.5208C30.6942 23.5208 31.8664 24.693 31.8664 26.139V31.1374M20.4414 23.5208V31.1374"
        stroke="white"
        stroke-width="2.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M21.7108 19.7128C21.7108 20.4139 21.1424 20.9822 20.4413 20.9822C19.7402 20.9822 19.1719 20.4139 19.1719 19.7128C19.1719 19.0117 19.7402 18.4434 20.4413 18.4434C21.1424 18.4434 21.7108 19.0117 21.7108 19.7128Z"
        fill="white"
      />
      <path
        d="M14.7285 20.6C14.7285 17.8502 14.7285 16.4753 15.4288 15.5115C15.6549 15.2002 15.9287 14.9264 16.24 14.7003C17.2038 14 18.5787 14 21.3285 14H30.9785C33.7283 14 35.1032 14 36.0671 14.7003C36.3783 14.9264 36.6521 15.2002 36.8782 15.5115C37.5785 16.4753 37.5785 17.8502 37.5785 20.6V30.25C37.5785 32.9998 37.5785 34.3747 36.8782 35.3385C36.6521 35.6498 36.3783 35.9236 36.0671 36.1497C35.1032 36.85 33.7283 36.85 30.9785 36.85H21.3285C18.5787 36.85 17.2038 36.85 16.24 36.1497C15.9287 35.9236 15.6549 35.6498 15.4288 35.3385C14.7285 34.3747 14.7285 32.9998 14.7285 30.25V20.6Z"
        stroke="white"
        stroke-width="1.5"
        stroke-linejoin="round"
      />
    </svg>
  );
};
export const Likewhite = () => {
  return (
    <svg
      width="17"
      height="15"
      viewBox="0 0 17 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.90169 0.161865C2.37771 0.161865 0.318359 2.22122 0.318359 4.7452C0.318359 6.45193 1.60488 8.05891 3.17318 9.70695C4.74148 11.355 6.67185 13.0241 8.2098 14.5621C8.32701 14.6793 8.48596 14.7451 8.65169 14.7451C8.81742 14.7451 8.97637 14.6793 9.09359 14.5621C10.6315 13.0241 12.5619 11.355 14.1302 9.70695C15.6985 8.05891 16.985 6.45193 16.985 4.7452C16.985 2.22122 14.9257 0.161865 12.4017 0.161865C10.9532 0.161865 9.69204 0.939569 8.65169 2.26066C7.61135 0.939569 6.35021 0.161865 4.90169 0.161865ZM4.90169 1.41187C6.12193 1.41187 7.13765 2.0612 8.12354 3.62052C8.17991 3.70959 8.2579 3.78296 8.35024 3.8338C8.44258 3.88463 8.54628 3.91129 8.65169 3.91129C8.7571 3.91129 8.8608 3.88463 8.95315 3.8338C9.04549 3.78296 9.12348 3.70959 9.17985 3.62052C10.1657 2.0612 11.1815 1.41187 12.4017 1.41187C14.2502 1.41187 15.735 2.89668 15.735 4.7452C15.735 5.7468 14.7299 7.26482 13.2253 8.84595C11.8532 10.2878 10.1477 11.7941 8.65169 13.2527C7.15573 11.7941 5.4502 10.2878 4.07813 8.84595C2.57351 7.26482 1.56836 5.7468 1.56836 4.7452C1.56836 2.89668 3.05318 1.41187 4.90169 1.41187Z"
        fill="#7F7F7F"
      />
    </svg>
  );
};

export const LikeRed = () => {
  return (
    <svg
      width="17"
      height="15"
      viewBox="0 0 17 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.90169 0.161865C2.37771 0.161865 0.318359 2.22122 0.318359 4.7452C0.318359 6.45193 1.60488 8.05891 3.17318 9.70695C4.74148 11.355 6.67185 13.0241 8.2098 14.5621C8.32701 14.6793 8.48596 14.7451 8.65169 14.7451C8.81742 14.7451 8.97637 14.6793 9.09359 14.5621C10.6315 13.0241 12.5619 11.355 14.1302 9.70695C15.6985 8.05891 16.985 6.45193 16.985 4.7452C16.985 2.22122 14.9257 0.161865 12.4017 0.161865C10.9532 0.161865 9.69204 0.939569 8.65169 2.26066C7.61135 0.939569 6.35021 0.161865 4.90169 0.161865ZM4.90169 1.41187C6.12193 1.41187 7.13765 2.0612 8.12354 3.62052C8.17991 3.70959 8.2579 3.78296 8.35024 3.8338C8.44258 3.88463 8.54628 3.91129 8.65169 3.91129C8.7571 3.91129 8.8608 3.88463 8.95315 3.8338C9.04549 3.78296 9.12348 3.70959 9.17985 3.62052C10.1657 2.0612 11.1815 1.41187 12.4017 1.41187C14.2502 1.41187 15.735 2.89668 15.735 4.7452C15.735 5.7468 14.7299 7.26482 13.2253 8.84595C11.8532 10.2878 10.1477 11.7941 8.65169 13.2527C7.15573 11.7941 5.4502 10.2878 4.07813 8.84595C2.57351 7.26482 1.56836 5.7468 1.56836 4.7452C1.56836 2.89668 3.05318 1.41187 4.90169 1.41187Z"
        fill="#FF0000"
      />
    </svg>
  );
};

export const Paginationleft = () => {
  return (
    <svg
      width="40"
      height="39"
      viewBox="0 0 40 39"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle
        cx="20.0214"
        cy="19.5402"
        r="18.5935"
        fill="white"
        stroke="#D4D4D4"
        stroke-width="0.953514"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M20.9752 25.2614C20.7311 25.2614 20.487 25.1679 20.3011 24.982L15.5335 20.2144C15.1607 19.8416 15.1607 19.239 15.5335 18.8662L20.3011 14.0986C20.6739 13.7258 21.2765 13.7258 21.6494 14.0986C22.0222 14.4714 22.0222 15.074 21.6494 15.4469L17.5559 19.5403L21.6494 23.6337C22.0222 24.0065 22.0222 24.6092 21.6494 24.982C21.4634 25.1679 21.2193 25.2614 20.9752 25.2614Z"
        fill="#7F7F7F"
      />
    </svg>
  );
};
export const Paginationright = () => {
  return (
    <svg
      width="40"
      height="39"
      viewBox="0 0 40 39"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle
        cx="19.9804"
        cy="19.5402"
        r="18.5935"
        fill="white"
        stroke="#D4D4D4"
        stroke-width="0.953514"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M17.5963 25.2614C17.3522 25.2614 17.1081 25.1679 16.9222 24.982C16.5494 24.6092 16.5494 24.0065 16.9222 23.6337L21.0156 19.5403L16.9222 15.4469C16.5494 15.074 16.5494 14.4714 16.9222 14.0986C17.295 13.7258 17.8976 13.7258 18.2705 14.0986L23.038 18.8662C23.4109 19.239 23.4109 19.8416 23.038 20.2144L18.2705 24.982C18.0845 25.1679 17.8404 25.2614 17.5963 25.2614Z"
        fill="#7F7F7F"
      />
    </svg>
  );
};
export const Fouricon = () => {
  return (
    <svg
      width="22"
      height="23"
      viewBox="0 0 22 23"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0 1.5C0 1.22917 0.0989583 0.994791 0.296875 0.796875C0.494792 0.598959 0.729167 0.5 1 0.5H9C9.27083 0.5 9.50521 0.598959 9.70312 0.796875C9.90104 0.994791 10 1.22917 10 1.5V9.5C10 9.77083 9.90104 10.0052 9.70312 10.2031C9.50521 10.401 9.27083 10.5 9 10.5H1C0.729167 10.5 0.494792 10.401 0.296875 10.2031C0.0989583 10.0052 0 9.77083 0 9.5V1.5ZM2 2.5V8.5H8V2.5H2ZM12 1.5C12 1.22917 12.099 0.994791 12.2969 0.796875C12.4948 0.598959 12.7292 0.5 13 0.5H21C21.2708 0.5 21.5052 0.598959 21.7031 0.796875C21.901 0.994791 22 1.22917 22 1.5V9.5C22 9.77083 21.901 10.0052 21.7031 10.2031C21.5052 10.401 21.2708 10.5 21 10.5H13C12.7292 10.5 12.4948 10.401 12.2969 10.2031C12.099 10.0052 12 9.77083 12 9.5V1.5ZM14 2.5V8.5H20V2.5H14ZM0 13.5C0 13.2292 0.0989583 12.9948 0.296875 12.7969C0.494792 12.599 0.729167 12.5 1 12.5H9C9.27083 12.5 9.50521 12.599 9.70312 12.7969C9.90104 12.9948 10 13.2292 10 13.5V21.5C10 21.7708 9.90104 22.0052 9.70312 22.2031C9.50521 22.401 9.27083 22.5 9 22.5H1C0.729167 22.5 0.494792 22.401 0.296875 22.2031C0.0989583 22.0052 0 21.7708 0 21.5V13.5ZM2 14.5V20.5H8V14.5H2ZM12 13.5C12 13.2292 12.099 12.9948 12.2969 12.7969C12.4948 12.599 12.7292 12.5 13 12.5H21C21.2708 12.5 21.5052 12.599 21.7031 12.7969C21.901 12.9948 22 13.2292 22 13.5V21.5C22 21.7708 21.901 22.0052 21.7031 22.2031C21.5052 22.401 21.2708 22.5 21 22.5H13C12.7292 22.5 12.4948 22.401 12.2969 22.2031C12.099 22.0052 12 21.7708 12 21.5V13.5ZM14 14.5V20.5H20V14.5H14Z"
        fill="black"
      />
    </svg>
  );
};
export const MenuSvg = () => {
  return (
    <svg
      width="24"
      height="19"
      viewBox="0 0 24 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0 9.5C0 9.22917 0.0989583 8.99479 0.296875 8.79688C0.494792 8.59896 0.729167 8.5 1 8.5H23C23.2708 8.5 23.5052 8.59896 23.7031 8.79688C23.901 8.99479 24 9.22917 24 9.5C24 9.77083 23.901 10.0052 23.7031 10.2031C23.5052 10.401 23.2708 10.5 23 10.5H1C0.729167 10.5 0.494792 10.401 0.296875 10.2031C0.0989583 10.0052 0 9.77083 0 9.5ZM0 1.5C0 1.22917 0.0989583 0.994791 0.296875 0.796875C0.494792 0.598959 0.729167 0.5 1 0.5H23C23.2708 0.5 23.5052 0.598959 23.7031 0.796875C23.901 0.994791 24 1.22917 24 1.5C24 1.77083 23.901 2.00521 23.7031 2.20312C23.5052 2.40104 23.2708 2.5 23 2.5H1C0.729167 2.5 0.494792 2.40104 0.296875 2.20312C0.0989583 2.00521 0 1.77083 0 1.5ZM0 17.5C0 17.2292 0.0989583 16.9948 0.296875 16.7969C0.494792 16.599 0.729167 16.5 1 16.5H23C23.2708 16.5 23.5052 16.599 23.7031 16.7969C23.901 16.9948 24 17.2292 24 17.5C24 17.7708 23.901 18.0052 23.7031 18.2031C23.5052 18.401 23.2708 18.5 23 18.5H1C0.729167 18.5 0.494792 18.401 0.296875 18.2031C0.0989583 18.0052 0 17.7708 0 17.5Z"
        fill="#7E7E7E"
      />
    </svg>
  );
};
export const Menuarrow = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4 6L8 10L12 6"
        stroke="#c9bdbe"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
export const PriceArrow = () => {
  return (
    <svg
      width="12"
      height="7"
      viewBox="0 0 12 7"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11 1L6 6L1 1"
        stroke="black"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};
export const PriceArrowrevers = () => {
  return (
    <svg
      width="12"
      height="7"
      viewBox="0 0 12 7"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.999512 5.99927L5.99951 0.999268L10.9995 5.99927"
        stroke="black"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};
export const Carticon = () => {
  return (
    <svg
      width="17"
      height="15"
      viewBox="0 0 17 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1 0.833252H1.50526C2.35578 0.833252 3.06986 1.47367 3.1621 2.31917L3.8379 8.514C3.93014 9.3595 4.64422 9.99992 5.49474 9.99992H12.705C13.4669 9.99992 14.1317 9.48332 14.32 8.7451L15.4699 4.23584C15.7387 3.18204 14.9425 2.15733 13.855 2.15733H4M4.02063 13.0207H4.64563M4.02063 13.6457H4.64563M13.1873 13.0207H13.8123M13.1873 13.6457H13.8123M5.16667 13.3333C5.16667 13.7935 4.79357 14.1666 4.33333 14.1666C3.8731 14.1666 3.5 13.7935 3.5 13.3333C3.5 12.873 3.8731 12.4999 4.33333 12.4999C4.79357 12.4999 5.16667 12.873 5.16667 13.3333ZM14.3333 13.3333C14.3333 13.7935 13.9602 14.1666 13.5 14.1666C13.0398 14.1666 12.6667 13.7935 12.6667 13.3333C12.6667 12.873 13.0398 12.4999 13.5 12.4999C13.9602 12.4999 14.3333 12.873 14.3333 13.3333Z"
        stroke="white"
        stroke-width="1.5"
        stroke-linecap="round"
      />
    </svg>
  );
};
export const Card = () => {
  return (
    <svg
      width="45"
      height="44"
      viewBox="0 0 45 44"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="22.5" cy="22" r="22" fill="#F6F6F6" />
      <path
        d="M15.5 18.75H30.5M18.5 26.25H21.5M24.5 26.25H27.5M17.9 30H28.1C28.9401 30 29.3601 30 29.681 29.7956C29.9632 29.6159 30.1927 29.329 30.3365 28.9762C30.5 28.5751 30.5 28.0501 30.5 27V18C30.5 16.9499 30.5 16.4249 30.3365 16.0238C30.1927 15.671 29.9632 15.3841 29.681 15.2044C29.3601 15 28.9401 15 28.1 15H17.9C17.0599 15 16.6399 15 16.319 15.2044C16.0368 15.3841 15.8073 15.671 15.6635 16.0238C15.5 16.4249 15.5 16.9499 15.5 18V27C15.5 28.0501 15.5 28.5751 15.6635 28.9762C15.8073 29.329 16.0368 29.6159 16.319 29.7956C16.6399 30 17.0599 30 17.9 30Z"
        stroke="#3C4242"
        stroke-width="1.1"
        stroke-linecap="round"
      />
    </svg>
  );
};
export const Shourticon = () => {
  return (
    <svg
      width="45"
      height="44"
      viewBox="0 0 45 44"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="22.5" cy="22" r="22" fill="#F6F6F6" />
      <path
        d="M26.1349 30H18.8651C17.8613 30 17.0476 29.2007 17.0476 28.2147V21.5796C17.0476 21.0634 16.4122 20.8049 16.0406 21.1699C15.7014 21.5032 15.1205 21.3203 15.0417 20.8555L14.5248 17.8091C14.4021 17.0857 14.7422 16.3621 15.3825 15.9847L18.6965 14.0315C18.793 13.9746 18.9175 13.9966 18.9878 14.0829C20.7884 16.2938 24.2116 16.2938 26.0122 14.0829C26.0825 13.9966 26.207 13.9746 26.3035 14.0315L29.6175 15.9847C30.2578 16.3621 30.5979 17.0857 30.4752 17.8091L29.9583 20.8555C29.8795 21.3203 29.2986 21.5032 28.9594 21.1699C28.5878 20.8049 27.9524 21.0634 27.9524 21.5796V28.2147C27.9524 29.2007 27.1387 30 26.1349 30Z"
        stroke="#3C4242"
        stroke-width="1.1"
      />
    </svg>
  );
};
export const Troukicon = () => {
  return (
    <svg
      width="45"
      height="44"
      viewBox="0 0 45 44"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="22.5" cy="22" r="22" fill="#F6F6F6" />
      <path
        d="M24.3 26.6667V15.4667C24.3 15.2089 24.0985 15 23.85 15H13.95C13.7015 15 13.5 15.2089 13.5 15.4667V26.6667C13.5 26.9244 13.7015 27.1333 13.95 27.1333H15.3M24.3 26.6667C24.3 26.9244 24.0985 27.1333 23.85 27.1333H18.9M24.3 26.6667V18.2667C24.3 18.0089 24.5015 17.8 24.75 17.8H27.7136C27.833 17.8 27.9474 17.8492 28.0318 17.9367L31.3682 21.3967C31.4526 21.4842 31.5 21.6029 31.5 21.7266V26.6667C31.5 26.9244 31.2985 27.1333 31.05 27.1333H29.7M24.3 26.6667C24.3 26.9244 24.5015 27.1333 24.75 27.1333H26.1M15.3 27.1333C15.3 28.1643 16.1059 29 17.1 29C18.0941 29 18.9 28.1643 18.9 27.1333M15.3 27.1333C15.3 26.1024 16.1059 25.2667 17.1 25.2667C18.0941 25.2667 18.9 26.1024 18.9 27.1333M26.1 27.1333C26.1 28.1643 26.9059 29 27.9 29C28.8941 29 29.7 28.1643 29.7 27.1333M26.1 27.1333C26.1 26.1024 26.9059 25.2667 27.9 25.2667C28.8941 25.2667 29.7 26.1024 29.7 27.1333"
        stroke="#3C4242"
        stroke-width="1.1"
      />
    </svg>
  );
};
export const Shifticon = () => {
  return (
    <svg
      width="45"
      height="44"
      viewBox="0 0 45 44"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="22.5" cy="22" r="22" fill="#F6F6F6" />
      <path
        d="M18.9444 28.2222C18.9444 29.2041 18.1485 30 17.1667 30C16.1848 30 15.3889 29.2041 15.3889 28.2222C15.3889 27.2404 16.1848 26.4444 17.1667 26.4444C18.1485 26.4444 18.9444 27.2404 18.9444 28.2222ZM18.9444 28.2222H26.0556C27.0374 28.2222 27.8333 27.4263 27.8333 26.4444V22.8889M26.0556 15.7778C26.0556 16.7596 26.8515 17.5556 27.8333 17.5556C28.8152 17.5556 29.6111 16.7596 29.6111 15.7778C29.6111 14.7959 28.8152 14 27.8333 14C26.8515 14 26.0556 14.7959 26.0556 15.7778ZM26.0556 15.7778H18.9444C17.9626 15.7778 17.1667 16.5737 17.1667 17.5556V21.1111M30.5 24.6667L28.1476 22.1398C27.974 21.9534 27.6926 21.9534 27.5191 22.1398L25.1667 24.6667M19.8333 19.3333L17.4809 21.8602C17.3074 22.0466 17.026 22.0466 16.8524 21.8602L14.5 19.3333"
        stroke="#3C4242"
        stroke-width="1.1"
        stroke-linecap="round"
      />
    </svg>
  );
};
export const Color1 = () => {
  return (
    <svg
      width="6"
      height="28"
      viewBox="0 0 6 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="6" height="28" rx="3" fill="#D85D5D" />
    </svg>
  );
};
export const BradeArrow = () => {
  return (
    <svg
      width="6"
      height="10"
      viewBox="0 0 6 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0 8.91921V1.47121C2.81495e-05 1.33938 0.0391425 1.21051 0.112399 1.10091C0.185655 0.9913 0.289764 0.905873 0.411563 0.855427C0.533363 0.80498 0.667384 0.791778 0.796686 0.81749C0.925988 0.843202 1.04476 0.906673 1.138 0.999879L4.86199 4.72388C4.98697 4.8489 5.05718 5.01844 5.05718 5.19521C5.05718 5.37199 4.98697 5.54153 4.86199 5.66655L1.138 9.39055C1.04476 9.48375 0.925988 9.54722 0.796686 9.57293C0.667384 9.59865 0.533363 9.58544 0.411563 9.535C0.289764 9.48455 0.185655 9.39912 0.112399 9.28952C0.0391425 9.17991 2.81495e-05 9.05105 0 8.91921Z"
        fill="#C2C6CE"
      />
    </svg>
  );
};
export const HomeNext = ({ forward }) => {
  return (
    <svg
      width="44"
      height="44"
      viewBox="0 0 44 44"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="44" height="44" rx="22" fill="#F0F2F3" />
      <path
        d="M25.5 17.5L30 22M30 22L25.5 26.5M30 22H14"
        stroke="#C86550"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};
export const HomeNexts = ({ forward }) => {
  return (
    <svg
      width="44"
      height="44"
      viewBox="0 0 44 44"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="44" height="44" rx="22" fill="#C86550" />
      <path
        d="M25.5 17.5L30 22M30 22L25.5 26.5M30 22H14"
        stroke="white"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};
export const HomePrev = ({ handleClick }) => {
  return (
    <svg
      width="44"
      height="44"
      viewBox="0 0 44 44"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        width="44"
        height="44"
        rx="22"
        transform="matrix(-1 0 0 1 44 0)"
        fill="#C86550"
      />
      <rect
        width="24"
        height="24"
        transform="matrix(-1 0 0 1 34 10)"
        fill="#C86550"
      />
      <path
        d="M18.5 17.5L14 22M14 22L18.5 26.5M14 22H30"
        stroke="white"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};
export const Bin = () => {
  return (
    <svg
      width="24"
      height="22"
      viewBox="0 0 24 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M22.1886 3.9375H18.4007V1.75C18.4007 0.784766 17.5514 0 16.5068 0H7.03703C5.9924 0 5.14308 0.784766 5.14308 1.75V3.9375H1.35518C0.831383 3.9375 0.408203 4.32852 0.408203 4.8125V5.6875C0.408203 5.80781 0.514738 5.90625 0.644947 5.90625H2.43236L3.16331 20.207C3.21066 21.1395 4.04518 21.875 5.0543 21.875H18.4895C19.5016 21.875 20.3332 21.1422 20.3805 20.207L21.1114 5.90625H22.8989C23.0291 5.90625 23.1356 5.80781 23.1356 5.6875V4.8125C23.1356 4.32852 22.7124 3.9375 22.1886 3.9375ZM16.27 3.9375H7.27377V1.96875H16.27V3.9375Z"
        fill="#C86550"
      />
    </svg>
  );
};
export const Closeicon = () => {
  return (
    <svg
      width="84"
      height="24"
      viewBox="0 0 44 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1 1.00001L12.4 13M1.60001 13L13 1"
        stroke="white"
        stroke-width="2"
        stroke-linecap="round"
      />
    </svg>
  );
};

export const CrossIcon = () => {
  return (
      <svg width="8" height="9" viewBox="0 0 8 9" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M7.90251 0.597595C7.84 0.535105 7.75523 0.5 7.66684 0.5C7.57845 0.5 7.49368 0.535105 7.43117 0.597595L4.00017 4.0286L0.569173 0.597595C0.506664 0.535105 0.421894 0.5 0.333506 0.5C0.245118 0.5 0.160349 0.535105 0.0978396 0.597595C0.0353493 0.660105 0.000244141 0.744874 0.000244141 0.833262C0.000244141 0.92165 0.0353493 1.00642 0.0978396 1.06893L3.52884 4.49993L0.0978396 7.93093C0.0353493 7.99344 0.000244141 8.07821 0.000244141 8.1666C0.000244141 8.25498 0.0353493 8.33975 0.0978396 8.40226C0.160349 8.46475 0.245118 8.49986 0.333506 8.49986C0.421894 8.49986 0.506664 8.46475 0.569173 8.40226L4.00017 4.97126L7.43117 8.40226C7.49368 8.46475 7.57845 8.49986 7.66684 8.49986C7.75523 8.49986 7.84 8.46475 7.90251 8.40226C7.965 8.33975 8.0001 8.25498 8.0001 8.1666C8.0001 8.07821 7.965 7.99344 7.90251 7.93093L4.47151 4.49993L7.90251 1.06893C7.965 1.00642 8.0001 0.92165 8.0001 0.833262C8.0001 0.744874 7.965 0.660105 7.90251 0.597595Z" fill="white" />
      </svg>
  )
}


export const Star12 = () => {
  return (
    <svg width="12" height="11" viewBox="0 0 12 11" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M5.04894 0.927049C5.3483 0.00573826 6.6517 0.00573993 6.95106 0.927051L7.5716 2.83688C7.70547 3.2489 8.08943 3.52786 8.52265 3.52786H10.5308C11.4995 3.52786 11.9023 4.76748 11.1186 5.33688L9.49395 6.51722C9.14347 6.77187 8.99681 7.22323 9.13068 7.63525L9.75122 9.54508C10.0506 10.4664 8.9961 11.2325 8.21238 10.6631L6.58778 9.48278C6.2373 9.22813 5.7627 9.22814 5.41221 9.48278L3.78761 10.6631C3.0039 11.2325 1.94942 10.4664 2.24878 9.54508L2.86932 7.63526C3.00319 7.22323 2.85653 6.77186 2.50604 6.51722L0.881445 5.33688C0.0977311 4.76748 0.500508 3.52786 1.46923 3.52786H3.47735C3.91057 3.52786 4.29453 3.2489 4.4284 2.83688L5.04894 0.927049Z" fill="#E4A70A"/>
    </svg>
  );
};
export const Star17 = () => {
  return (
    
<svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
<circle cx="9" cy="9" r="8.5" transform="rotate(-90 9 9)" stroke="#C86550"/>
<path d="M14 9L7.25 13.3301L7.25 4.66987L14 9Z" fill="#C86550"/>
</svg>

  );
};
export const Close44 = () => {
  return (
    


<svg width="20" height="23" viewBox="0 0 20 23" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fill-rule="evenodd" clip-rule="evenodd" d="M10 0.700439C4.47727 0.700439 0 5.52932 0 11.4858C0 17.4422 4.47727 22.2711 10 22.2711C15.5227 22.2711 20 17.4422 20 11.4858C20 5.52932 15.5227 0.700439 10 0.700439ZM13.37 9.23751C13.5356 9.05259 13.6272 8.80492 13.6252 8.54784C13.6231 8.29076 13.5275 8.04484 13.3589 7.86305C13.1904 7.68126 12.9624 7.57815 12.724 7.57591C12.4856 7.57368 12.256 7.67251 12.0845 7.85111L10 10.0994L7.91545 7.85111C7.83159 7.75746 7.73128 7.68277 7.62037 7.63138C7.50946 7.57999 7.39016 7.55295 7.26946 7.55182C7.14875 7.55068 7.02904 7.57549 6.91731 7.62479C6.80559 7.67409 6.70409 7.7469 6.61873 7.83896C6.53338 7.93102 6.46587 8.04049 6.42016 8.16099C6.37445 8.28148 6.35145 8.41059 6.3525 8.54078C6.35355 8.67097 6.37863 8.79963 6.42627 8.91925C6.47392 9.03888 6.54317 9.14707 6.63 9.23751L8.71455 11.4858L6.63 13.734C6.54317 13.8245 6.47392 13.9327 6.42627 14.0523C6.37863 14.1719 6.35355 14.3006 6.3525 14.4307C6.35145 14.5609 6.37445 14.69 6.42016 14.8105C6.46587 14.931 6.53338 15.0405 6.61873 15.1326C6.70409 15.2246 6.80559 15.2974 6.91731 15.3467C7.02904 15.396 7.14875 15.4208 7.26946 15.4197C7.39016 15.4186 7.50946 15.3915 7.62037 15.3401C7.73128 15.2888 7.83159 15.2141 7.91545 15.1204L10 12.8722L12.0845 15.1204C12.256 15.299 12.4856 15.3978 12.724 15.3956C12.9624 15.3934 13.1904 15.2903 13.3589 15.1085C13.5275 14.9267 13.6231 14.6808 13.6252 14.4237C13.6272 14.1666 13.5356 13.9189 13.37 13.734L11.2855 11.4858L13.37 9.23751Z" fill="#C86550"/>
</svg>



  );
};
export const Dot1 = () => {
  return (
    



<svg width="4" height="10" viewBox="0 0 4 4" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M1.87 3.546C1.394 3.546 0.988 3.38267 0.652 3.056C0.325333 2.72 0.162 2.314 0.162 1.838C0.162 1.35267 0.325333 0.946666 0.652 0.62C0.988 0.284 1.394 0.116 1.87 0.116C2.35533 0.116 2.766 0.284 3.102 0.62C3.438 0.946666 3.606 1.35267 3.606 1.838C3.606 2.314 3.438 2.72 3.102 3.056C2.766 3.38267 2.35533 3.546 1.87 3.546Z" fill="#4D4D4D"/>
</svg>




  );
};
export const Arrow1 = () => {
  return (
    




<svg width="8" height="15" viewBox="0 0 5 11" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M5.51953 1.32031L1.19953 5.64031L5.51953 9.96031" stroke="black" stroke-width="1.44"/>
</svg>




  );
};
export const Plus = () => {
  return (
 
<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">

<path fill-rule="evenodd"  clip-rule="evenodd" d="M24 12C24 18.6274 18.6274 24 12 24C5.37258 24 0 18.6274 0 12C0 5.37258 5.37258 0 12 0C18.6274 0 24 5.37258 24 12ZM12 18C11.4477 18 11 17.5523 11 17V13H7C6.44772 13 6 12.5523 6 12C6 11.4477 6.44772 11 7 11H11V7C11 6.44771 11.4477 6 12 6C12.5523 6 13 6.44771 13 7V11H17C17.5523 11 18 11.4477 18 12C18 12.5523 17.5523 13 17 13H13V17C13 17.5523 12.5523 18 12 18Z" fill="#C86550"/>
</svg>

  );
};

export const Arrpw1 = () => {
  return (
 

<svg width="11" height="7" viewBox="0 0 11 7" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M1.32031 0.979736L5.64031 5.29974L9.96031 0.979736" stroke="#BBBBBB" stroke-width="1.44"/>
</svg>

  );
};

export const Trck = () => {
  return (
 


<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M8.25002 18.75C8.25002 19.1478 8.09198 19.5293 7.81068 19.8107C7.52937 20.092 7.14784 20.25 6.75002 20.25C6.35219 20.25 5.97066 20.092 5.68936 19.8107C5.40805 19.5293 5.25002 19.1478 5.25002 18.75M8.25002 18.75C8.25002 18.3522 8.09198 17.9706 7.81068 17.6893C7.52937 17.408 7.14784 17.25 6.75002 17.25C6.35219 17.25 5.97066 17.408 5.68936 17.6893C5.40805 17.9706 5.25002 18.3522 5.25002 18.75M8.25002 18.75H14.25M5.25002 18.75H3.37502C3.07665 18.75 2.7905 18.6315 2.57952 18.4205C2.36855 18.2095 2.25002 17.9234 2.25002 17.625V14.25M14.25 18.75H16.5M14.25 18.75V14.25M2.25002 14.25V6.61499C2.24843 6.34116 2.34848 6.07648 2.53081 5.87217C2.71313 5.66786 2.96477 5.53845 3.23702 5.50899C6.57006 5.16302 9.92997 5.16302 13.263 5.50899C13.828 5.56699 14.25 6.04699 14.25 6.61499V7.57299M2.25002 14.25H14.25M19.5 18.75C19.5 19.1478 19.342 19.5293 19.0607 19.8107C18.7794 20.092 18.3978 20.25 18 20.25C17.6022 20.25 17.2207 20.092 16.9394 19.8107C16.6581 19.5293 16.5 19.1478 16.5 18.75M19.5 18.75C19.5 18.3522 19.342 17.9706 19.0607 17.6893C18.7794 17.408 18.3978 17.25 18 17.25C17.6022 17.25 17.2207 17.408 16.9394 17.6893C16.6581 17.9706 16.5 18.3522 16.5 18.75M19.5 18.75H20.625C21.246 18.75 21.754 18.246 21.715 17.626C21.5141 14.3236 20.4019 11.1416 18.502 8.43299C18.3211 8.17927 18.0848 7.97001 17.8111 7.82102C17.5373 7.67203 17.2333 7.58722 16.922 7.57299H14.25M14.25 7.57299V14.25" stroke="#D75951" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
</svg>


  );
};

export const FilterIcon = () => {
  return (
      <svg width="24" height="24" viewBox="0 0 24 24" fill="#C86550" xmlns="http://www.w3.org/2000/svg">
          <path d="M12 11.25L12 20.25" stroke="#C86550" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
          <path d="M12 3.75L12 8.25" stroke="#C86550" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
          <path d="M18.75 18.75L18.7501 20.25" stroke="#C86550" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
          <path d="M18.7501 3.75L18.75 15.75" stroke="#C86550" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
          <path d="M21 15.75H16.5" stroke="#C86550" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
          <path d="M5.25007 15.75L5.25 20.25" stroke="#C86550" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
          <path d="M5.25 3.75L5.25007 12.75" stroke="#C86550" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
          <path d="M3 12.75H7.5" stroke="#C86550" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
          <path d="M14.25 8.25H9.75" stroke="#C86550" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
      </svg>
    )
}

