import React, { useState } from "react";
import styles from "./faq.module.css"; // assuming you have styles here

const faqData = {
  "Topic 1": [
    {
      question: "What is Missy and Moppet?",
      answer:
        "Missy and Moppet is childrens clothing online brand that gives utmost importance to boys fashion ,when compared to girls fashion clothes. We cater to the needs of our buyers (mothers buying for their kids) fromthe kid is born up to his/her early teens. We make fashion statements for boys with girls. ",
    },
    {
      question: "What is the benefit of buying from Missy Moppet?",
      answer:
        "Our company philosophy is pretty simple: Why should girls have all the fashion . We have created a wide varieties of clothing sets for boys from patterns, to colors, to designs , to clothes etc.",
    },
    {
      question: "Where are the offices of Misseymoppet located?",
      answer: "Currently we are working online .",
    },
    {
      question: "What products does Misseymoppet sell?",
      answer:
        "We sell products like daily wears ,occasional wears , festive wears , designer wear for both boys and girls.",
    },
    {
      question: "What's the minimum order value required to place an order?",
      answer:
        "To place an order, a minimum order value of Rs. 999 or more is required (Excluding COD charges, Shipping charges, or value reduced after the application of coupons or any other offer).",
    },
  ],
  "Topic 2": [
    {
      question: "How do I know my order has been confirmed?",
      answer:
        "After checking out during the payment process, you will get a confirmation that your payment has been processed successfully. You will also get a mail in your registered email id, along with an whatsapp message to your registered mobile number confirming the order.",
    },
    {
      question: "How do I check my order status?",
      answer:
        "We will ship your package within 24 hours of receiving the order. We will also mail you the name of the courier company and the tracking number of your consignment to your registered email address. You will receive an SMS about the same. In case you do not receive an email from us within 24 hours of placing an order please check your spam folder. Tracking may not appear online for up to another 24 hours in some cases, so please wait until your package is scanned by the courier company. Alternatively, you can check the same in My Account page, where Track Shipment option gives you the current status of your order being shipped.",
    },
    {
      question: "Is it safe to use my Credit Card Online at missy and moppet?",
      answer:
        "We accept VISA, MASTER, AMERICAN EXPRESS and DINERS Credit Cards issued in India and we accept Debit cards of all banks issued in India. International credit/debit card transactions are also accepted through Razor pay, and you can place orders with Misseymoppet from outside India and we will ship it to your doors. All your transactions online are protected & secured by SSL (secure socket layer) technology from GoDaddy.com. It encrypts your credit card and relevant information during the entire transaction process. This encryption makes your shopping experience safe and secure.",
    },
    {
      question: "What if an item is out of stock?",
      answer:
        "We make every effort to keep all products in stock. However, there may be times when unexpected demands exceed our supply. We will notify you at the time of check out if the item selected is not in stock. In the rare circumstance where the order has been accepted and due to some reasons we are not able to ship, we will notify you within 24 hours and the money will be returned to you in the same mode by which the payment was made within 10 working days. Some items (which are restocked regularly) and is temporarily out of stock may appear on display with an out of stock tag. You can use the Notify Me option and we will inform you once the product is replenished and available for sale.",
    },
    {
      question: "Are there any other hidden charges like Octroi or Entry tax?",
      answer:
        "You will get the final price during check out. Our prices are all inclusive and you need not pay anything extra.",
    },
  ],
  "Topic 3": [
    {
      question: "How are the products packaged?",
      answer:
        "Each of the individual products is securely packaged and put into a box. The box is sealed well to ensure a safe delivery to your doorstep.",
    },
    {
      question: "Do you provide customer call service in India?",
      answer:
        "Currently, we do not offer phone support. International customers can reach us at info@misseymoppet.com for any service-related inquiries.",
    },
    {
      question: "Can I register using an international phone number?",
      answer: "Yes, you can register with an international phone number.",
    },
    {
      question: "Do you deliver products outside India?",
      answer: "Yes, we deliver products worldwide.",
    },
    {
      question: "Is it mandatory to register before placing an order?",
      answer:
        "Yes, it is mandatory to register before placing an order on MisseyMoppet. Registering allows you to receive timely offers and maintain your wishlist, account, and address details.",
    },
  ],

  "Topic 4": [
    {
      question: "How long will it take to receive my orders?",
      answer:
        "For all areas serviced by reputed couriers, delivery typically takes 3-4 business days after dispatch (business days exclude Sundays and holidays). Items weighing over 2 kilos may require an additional couple of days. For other areas, products are shipped through the Indian Postal Service and may take 1-2 weeks, depending on location.",
    },
    {
      question:
        "Can I ship different items in my order to different shipping addresses?",
      answer:
        "We can only process one shipping address per order. If you would like to order several items to ship to different people, please place separate orders for each address.",
    },
    {
      question: "Who pays for the shipping charges?",
      answer:
        "We offer free shipping on orders over Rs. 699 (excluding COD charges, shipping fees, taxes, or orders with discounts). For orders below Rs. 699, a shipping fee of Rs. 50 applies. Additional shipping costs may be applied to certain products or regions, regardless of order value.",
    },
    {
      question: "What is Platform and Convenience Fee?",
      answer:
        "Misseymoppet reserves the right to levy a Platform and Convenience Fee on orders. This fee supports continuous improvement of our shopping and fulfillment experience and is non-refundable in case of cancellations.",
    },
    {
      question: "What is GST?",
      answer:
        "GST stands for Goods and Services Tax, a single indirect tax on goods and services across India. GST applies to all products.",
    },
  ],
  "Topic 5": [
    {
      question:
        "How is the GST amount calculated over and above the discounted price?",
      answer:
        "GST is calculated based on rates outlined under GST laws, considering the product category, customer location, and applicable discount. GST applies over the discounted price on products with a discount of 19% or higher. For more details, please refer to our Terms of Use. The collected GST is deposited with relevant GST Authorities or adjusted per GST Act and Rules.",
    },
    {
      question:
        "Will my GST amount be refunded on Order Cancellation and Returns?",
      answer:
        "Yes. The GST amount collected will be refunded to the original payment method upon order cancellations or returns.",
    },
    {
      question:
        "What should I do if the GST amount on purchased products exceeds the government-defined limit?",
      answer:
        "We are committed to transparency in GST charges. Please reach out, and we will review the GST details. If any excess has been charged due to miscalculation, we will promptly refund the difference.",
    },
    {
      question: "What happens if I am not available at the time of delivery?",
      answer:
        "Our delivery partners will attempt delivery up to three times before returning the package to our warehouse. Please ensure your mobile number is provided in the delivery address to facilitate timely delivery.",
    },
    {
      question: "What is your Replacement Policy?",
      answer:
        "To view the Replacement Policy for each product, please visit the product or combo page. Replacements are available within the specified window after delivery. During pickup, some products may undergo a Quality Control Inspection to verify parameters such as brand, style, color, size, packaging, and condition. If requirements are unmet, the replacement may be put on hold. You may request another replacement as long as the window remains open. After successful inspection, the replacement product will be dispatched. The item should be unused, unwashed, with all tags intact and in original packaging with the invoice copy.",
    },
    {
      question: "What payment options do I have?",
      answer:
        "We accept debit and credit cards (VISA, MasterCard, RuPay, American Express, Diners), net banking (HDFC, ICICI, Kotak Mahindra, Indian Overseas, Yes Bank, Standard Chartered, City Union, Axis Bank for orders above Rs. 2000), and Cash on Delivery (COD). COD is available for orders above Rs. 99 and up to Rs. 10,000, with varying charges based on order history and pincode.",
    },
    {
      question: "What are the Cash on Delivery (COD) charges?",
      answer:
        "For orders equal to or above Rs. 699, COD charges vary by pincode and order history, with free shipping for eligible orders. For orders under Rs. 699, COD charges vary, and a fixed Rs. 50 shipping charge applies. COD charges may be waived if previous orders used prepaid methods.",
    },
    {
      question: "What is Quality Control Inspection at doorstep?",
      answer:
        "When you request a return, exchange, or replacement, our courier partner may conduct a quality check at your doorstep. They will verify parameters like brand, style, color, size, packaging, and overall condition. If all criteria are met, your request will proceed. If not, it may be placed on hold. You may submit another request within the designated window.",
    },
  ],
};

const FAQ = () => {
  const [selectedTopic, setSelectedTopic] = useState("Topic 1");
  const [expandedIndex, setExpandedIndex] = useState(null);

  const handleTopicClick = (topic) => {
    setSelectedTopic(topic);
    setExpandedIndex(null); // Reset the expanded question when topic changes
  };

  const handleToggle = (index) => {
    setExpandedIndex(expandedIndex === index ? null : index);
  };

  return (
    <div className={styles.container}>
      <div className={styles.section}>
        {/* Sidebar */}
        <div className={styles.sidebar}>
          {Object.keys(faqData).map((topic, index) => (
            <div
              key={index}
              className={`${styles.topic} ${
                selectedTopic === topic ? styles.active : ""
              } ${
                index + 1 == Object.keys(faqData).length
                  ? ""
                  : styles.topicBorder
              }`}
              onClick={() => handleTopicClick(topic)}
            >
              <div> {topic}</div>
            </div>
          ))}
        </div>

        {/* FAQ Content */}
        <div className={styles.content}>
        <h2 className={styles.heading}>{selectedTopic}</h2>
        {faqData[selectedTopic].map((item, index) => (
          <div key={index} className={styles.faqItem}>
            <div className={styles.question} onClick={() => handleToggle(index)}>
              {item.question}
              <span>{expandedIndex === index ? <div className={styles.nagative}>-</div>  : <div className={styles.positive}>+</div> }</span>
            </div>
            {expandedIndex === index && <div className={styles.answer}>{item.answer}</div>}
          </div>
        ))}
      </div>

        <div className={styles.mobile_contant}>
          {Object.keys(faqData).map((topic) => (
            <div className={styles.content1}>
              <h2 className={styles.heading}>{topic}</h2>
              {faqData[topic].map((item, index) => (
                <div key={index} className={styles.faqItem}>
                  <div
                    className={styles.question}
                    onClick={() => handleToggle(index)}
                  >
                    {item.question}
                    <span>
                      {expandedIndex === index ? (
                        <div className={styles.nagative}>-</div>
                      ) : (
                        <div className={styles.positive}>+</div>
                      )}
                    </span>
                  </div>
                  {expandedIndex === index && (
                    <div className={styles.answer}>{item.answer}</div>
                  )}
                </div>
              ))}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default FAQ;
