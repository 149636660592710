import {createAsyncThunk , createSlice} from "@reduxjs/toolkit"
import api from "../../utils/Api"
import toast from "../../utils/toast"
import { setPagination } from "./productSlice"


const initialState = {
    orders:[],
    pagination:{},
    lastOrder:{},
    singleOrder:{},
    selectedOrder:null,
    isLoading: false,
    error: null,
    orderReviews:[],
    orderToExchange: null,
    selectedItemsToExchange: [],
    invoice:{}
}


export const getLastOrder = createAsyncThunk("getLastOrder" , async (body , {rejectWithValue , dispatch}) => {
    try {
        const { data, status } = await api.getLastOrder();
  

        if (status === 200) {
            console.log("lastOrder",data.data);
            
            dispatch(setLastOrder(data.data));
        }
        
    } catch (error) {
        return rejectWithValue(error.response.data.message || "'Something went wrong. Please try again later.'")
    }
})


export const getOrderHistory = createAsyncThunk("getOrderHistory" , async ({page} , {rejectWithValue , dispatch}) => {
    try {
        dispatch(
            setData({
              name: "isLoading",
              value: true,
            })
          );
          dispatch(
            setData({
              name: "error",
              value: null,
            })
          );
        const { data, status } = await api.getOrderHistory(page);
  

        if (status === 200) {
            dispatch(
                setData({
                  name: "isLoading",
                  value: false,
                })
              );

            console.log("order History",data.data);
            
            dispatch(setOrders(data.data));
            dispatch(setOrderPagination(data?.data?.pagination))
        }
        
    } catch (error) {
        dispatch(
            setData({
              name: "isLoading",
              value: false,
            })
          );
          dispatch(
            setData({
              name: "error",
              value:
                error.response.data.message ||
                "'Something went wrong. Please try again later.'",
            })
          );
        return rejectWithValue(error.response.data.message || "'Something went wrong. Please try again later.'")
    }
})


export const getOrderDetails = createAsyncThunk("getOrderDetails" , async (orderId , {rejectWithValue , dispatch}) => {
    try {

        dispatch(
            setData({
              name: "isLoading",
              value: true,
            })
          );
          dispatch(
            setData({
              name: "error",
              value: null,
            })
          );
        const { data, status } = await api.getOrderDetails(orderId);
  

        if (status === 200) {
            console.log("order",data?.data);

            dispatch(
                setData({
                  name: "isLoading",
                  value: false,
                })
              );
            
            dispatch(setSingleOrder(data?.data?.data));
        }
        
    } catch (error) {

        dispatch(
            setData({
              name: "isLoading",
              value: false,
            })
          );
          dispatch(
            setData({
              name: "error",
              value:
                error.response.data.message ||
                "'Something went wrong. Please try again later.'",
            })
          );
        return rejectWithValue(error.response.data.message || "'Something went wrong. Please try again later.'")
    }
})




export const addReviewForProduct = createAsyncThunk("addReviewForProduct" , async (body , {rejectWithValue , dispatch}) => {
    try {
        const { data, status } = await api.addReviewForProduct(body);
  

        if (status === 200) {
            console.log("order",data?.data);
            toast.success("Review added successfully")
            dispatch(getAllCustomerReviews(body.orderId))
            return null
        }
        
    } catch (error) {
        toast.error(error.response.data.message)
        return rejectWithValue(error.response.data.message || "'Something went wrong. Please try again later.'")
    }
})

// getAllCustomerReviews
export const getAllCustomerReviews = createAsyncThunk("getAllCustomerReviews" , async (body , {rejectWithValue , dispatch}) => {
  try {
      const { data, status } = await api.getAllCustomerReviews(body);


      if (status === 200) {
          console.log("orderrrrrrrrrrrrrrrrrrrrrr",data?.data?.data);
          dispatch(setOrderReviews(data?.data.data))
      }
      
  } catch (error) {
      // toast.error(error.response.data.message)
      return rejectWithValue(error.response.data.message || "'Something went wrong. Please try again later.'")
  }
})


export const exchangeRequest = createAsyncThunk("exchangeRequest" , async ({orderId,formData} , {rejectWithValue , dispatch}) => {
    try {
        
        const { data, status } = await api.exchangeRequest(orderId,formData);
  

        if (status === 200) {
            console.log("exchange request",data?.data);
            toast.success("Exchange Request sent successfully")
            return null
        }
        
    } catch (error) {
        toast.error(error.response.data.message)
        return rejectWithValue(error.response.data.message || "'Something went wrong. Please try again later.'")
    }
})


export const getInvoice = createAsyncThunk("getInvoice" , async (orderId , {rejectWithValue , dispatch}) => {
  try {

    console.log("invoiceid",orderId)
      
      const { data, status } = await api.getInvoice(orderId);


      if (status === 200) {
          // console.log("invoice data",data?.data);
          dispatch(setInvoice(data.data.data))
      
      }
      return data.data.data
      
  } catch (error) {
      toast.error(error.response.data.message)
      return rejectWithValue(error.response.data.message || "'Something went wrong. Please try again later.'")
  }
})




const orderSlice = createSlice({
    name:"order",
    initialState,
    reducers:{
       setLastOrder:(state,action) => {
        state.lastOrder = action.payload;
       },
       setOrderPagination:(state,action) => {
        state.pagination = action.payload;
       },
       setOrders:(state,action) => {
          state.orders = action.payload;
       },
       setSingleOrder:(state,action) => {
        state.singleOrder = action.payload;
       },
       setSelectedOrder: (state,action) => {
        state.selectedOrder = action.payload;
       },
       setOrderReviews: (state,action) => {
        state.orderReviews = action.payload
       },
       setData: (state, action) => {
        state[action.payload.name] = action.payload.value;
      },
      setInvoice: (state,action) => {
        state.invoice = action.payload
      }
    }
})

export const {setLastOrder,setOrders,setSingleOrder,setOrderPagination,setSelectedOrder,setData,setOrderReviews,setInvoice} = orderSlice.actions
export default orderSlice.reducer

