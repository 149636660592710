import React, { useState, useEffect } from "react";
import styles from "./otp.module.css";
import Authheader from "../../smallcomponents/authheader/Authheader";
import LoginPopup from "../../smallcomponents/loginpopup/LoginPopup";
import { useDispatch, useSelector } from "react-redux";
import {
  verifySignUpWithOtp,
  verifyLoginWithOtp,
  resendOtpForSignUp,
  resendOtpForLogin,
} from "../../redux/reducers/userSlice";
import { useNavigate, useLocation } from "react-router-dom";
import { unwrapResult } from "@reduxjs/toolkit";

const Otp = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const { loading, error, success } = useSelector((state) => state.user);

  const [otp, setOtp] = useState(["", "", "", "", "", ""]);
  const [isLoginSuccessful, setIsLoginSuccessful] = useState(false);
  const [countdown, setCountdown] = useState(120);
  const [isResendDisabled, setIsResendDisabled] = useState(true);

  useEffect(() => {
    if (countdown > 0) {
      const timer = setTimeout(() => {
        setCountdown(countdown - 1);
      }, 1000);
      return () => clearTimeout(timer);
    } else {
      setIsResendDisabled(false);
    }
  }, [countdown]);

  const phone = localStorage.getItem("phone");
  const queryParams = new URLSearchParams(location.search);
  const mode = queryParams.get("mode");

  const handleInputChange = (e, index) => {
    const value = e.target.value;
    if (isNaN(value)) return;

    const newOtp = [...otp];
    newOtp[index] = value;
    setOtp(newOtp);

    if (value !== "" && index < otp.length - 1) {
      document.getElementById(`otp-input-${index + 1}`).focus();
    }
  };

  const handleKeyDown = (e, index) => {
    if (e.key === "Backspace" && otp[index] === "" && index > 0) {
      document.getElementById(`otp-input-${index - 1}`).focus();
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const otpValue = otp.join("");
      if (otpValue.length === otp.length) {
        let resultAction;
        if (mode === "signup") {
          resultAction = await dispatch(
            verifySignUpWithOtp({ otp: otpValue, phone })
          );
        } else if (mode === "login") {
          resultAction = await dispatch(
            verifyLoginWithOtp({ otp: otpValue, phone })
          );
        }

        unwrapResult(resultAction);

        setIsLoginSuccessful(true);
      }
    } catch (err) {}
  };

  const handleResendOtp = async (e) => {
    try {
      e.preventDefault();
      if (!isResendDisabled) {
        if (mode === "signup") {
          const resultAction = await dispatch(
            resendOtpForSignUp({ phone })
          );
          unwrapResult(resultAction);
        } else if (mode === "login") {
          const resultAction = await dispatch(
            resendOtpForLogin({ phone })
          );
          unwrapResult(resultAction);
        }

        setCountdown(120);
        setIsResendDisabled(true);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (isLoginSuccessful) {
      navigate("/");
    }
  }, [isLoginSuccessful, navigate]);

  const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${minutes}:${remainingSeconds < 10 ? "0" : ""}${remainingSeconds}`;
  };

  return (
    <>
      <Authheader />
      <div className={styles.login_container}>
        <div className={styles.image_section}>
          <img src="./assets/images/babylogin.png" alt="Baby" />
        </div>
        <div className={styles.form_section}>
          <div className={styles.arrowback}>
            <img src="./assets/images/arrow-left.png" alt="arrow-left" />
            <span onClick={() => navigate(`/${mode}`)}>Back</span>
          </div>
          <h2>Enter OTP</h2>
          <p>We have shared a code to your registered phone number +91 {phone}</p>
          <div className={styles.form_container}>
            <div className={styles.number_boxes}>
              {otp.map((digit, index) => (
                <input
                  key={index}
                  type="text"
                  className={styles.box}
                  id={`otp-input-${index}`}
                  maxLength="1"
                  value={digit}
                  onChange={(e) => handleInputChange(e, index)}
                  onKeyDown={(e) => handleKeyDown(e, index)}
                />
              ))}
            </div>
            <button type="submit" onClick={handleSubmit} disabled={loading}>
              {loading ? "Verifying..." : mode === "signup" ? "Signup" : "Login"}
            </button>

            {error && <p className={styles.error}>{error}</p>}
            {success && <p className={styles.success}>OTP Verified!</p>}

            <div className={styles.newuser}>
              {isResendDisabled ? (
                <p>Resend OTP in {formatTime(countdown)}</p>
              ) : (
                <p>
                  Didn't receive OTP? &nbsp;
                  <span className={styles.signup} onClick={handleResendOtp}>
                    Resend
                  </span>
                </p>
              )}
            </div>
          </div>
        </div>
      </div>

      {isLoginSuccessful && <LoginPopup message="Login Successful" />}
    </>
  );
};

export default Otp;
