import {createAsyncThunk , createSlice} from "@reduxjs/toolkit"
import api from "../../utils/Api"
import toast from "../../utils/toast"
import { getCartProducts } from "./cartSlice"


const initialState = {
    wishlistData:[],
    isLoading:false,
    error: null,
}




export const getWishlist = createAsyncThunk("getWishlist" , async (body , {rejectWithValue , dispatch}) => {
    try {

        dispatch(
            setData({
              name: "isLoading",
              value: true,
            })
          );
          dispatch(
            setData({
              name: "error",
              value: null,
            })
          );

        const { data, status } = await api.getWishlist();
  

        if (status === 200) {

            dispatch(
                setData({
                  name: "isLoading",
                  value: false,
                })
              );

            console.log("wishlistData",data.data.data);
            
          dispatch(setWishlist(data.data.data));
        }
        
    } catch (error) {

        dispatch(
            setData({
              name: "isLoading",
              value: false,
            })
          );
          dispatch(
            setData({
              name: "error",
              value:
                error.response.data.message ||
                "'Something went wrong. Please try again later.'",
            })
          );

        return rejectWithValue(error.response.data.message || "'Something went wrong. Please try again later.'")
    }
})


export const addToWishlist = createAsyncThunk("addToWishlist" , async (body , {rejectWithValue , dispatch}) => {
    try {
       
        const { data, status } = await api.addToWishlist(body);
  

        if (status === 200) {
            console.log("wishlistData",data.data.data);

            // toast.success("Wishlist Updated")
            
          await  dispatch( getWishlist());

        }
        
    } catch (error) {
      toast.error(error.response.data.message)
        return rejectWithValue(error.response.data.message || "'Something went wrong. Please try again later.'")
    }
})




export const deleteFromWishlist = createAsyncThunk("deleteFromWishlist" , async (body , {rejectWithValue , dispatch}) => {
    try {
        console.log('boooody',body)

        
        const { data, status } = await api.deleteFromWishlist(body);

        
  
        if (status === 200) {
            
            dispatch(getWishlist());
            // toast.warning(data.data.message);
        }
        
    } catch (error) {
        return rejectWithValue(error.response.data.message || "'Something went wrong. Please try again later.'")
    }
})

export const moveFromWishlistToCart = createAsyncThunk("moveFromWishlistToCart" , async (productId , {rejectWithValue , dispatch}) => {
    try {
        const { data, status } = await api.moveFromWishlistToCart(productId);
  

        if (status === 200) {
            console.log("wishlistData",data.data.data);

            toast.success("Wishlist Updated")
            
          await dispatch( getWishlist());
          await dispatch(getCartProducts())

        }
        
    } catch (error) {
      toast.warning(error.response.data.message)
        return rejectWithValue(error.response.data.message || "'Something went wrong. Please try again later.'")
    }
})



export const deleteAllItemsFromWishlist = createAsyncThunk("deleteAllItemsFromWishlist" , async (body , {rejectWithValue , dispatch}) => {
  try {
      // console.log('boooody',body)

      
      const { data, status } = await api.deleteAllItemsFromWishlist();

      if (status === 200) {
          
          dispatch(getWishlist());
          toast.warning(data.data.message);
      }
      
  } catch (error) {
    toast.error(error.response.data.message)
      return rejectWithValue(error.response.data.message || "'Something went wrong. Please try again later.'")
  }
})



export const moveAllFromWishlistToCart = createAsyncThunk("moveAllFromWishlistToCart" , async (productId , {rejectWithValue , dispatch}) => {
  try {
      const { data, status } = await api.moveAllFromWishlistToCart();


      if (status === 200) {
          console.log("wishlistData",data.data.data);

          toast.success(data.data.message)
          
        await dispatch( getWishlist());
        await dispatch(getCartProducts());

      }
      
  } catch (error) {
    toast.warning(error.response.data.message)
      return rejectWithValue(error.response.data.message || "'Something went wrong. Please try again later.'")
  }
})













const wishlistSlice = createSlice({
    name:"wishlist",
    initialState,
    reducers:{
       setWishlist:(state,action) => {
        state.wishlistData = action.payload;
       },
       setData: (state, action) => {
        state[action.payload.name] = action.payload.value;
      },
    }
})


export const {setWishlist , setData} = wishlistSlice.actions
export default wishlistSlice.reducer