import React from 'react'
import styles from "./category.module.css"
import { useNavigate } from 'react-router-dom'

const Category = () => {

  const navigate = useNavigate()
  return (
    <div className={styles.container}>
      
    <div className={styles.section}>
    <div className={styles.box1}>
   
    <h2 className={styles.title}>Shop By <br/>
    Category</h2>
    <button className={styles.button_3} onClick={() => navigate(`/Category Product`)}>Shop Now</button>
 
    </div>
    <div className={styles.box2} onClick={() => navigate(`/Category Product`)}>
 
    <h2 className={styles.heading} >Baby</h2>
  
    </div>
    <div className={styles.box3} onClick={() => navigate(`/Category Product`)}>

    <h2 className={styles.heading} >Toddler</h2>
    
    </div>
    <div className={styles.box4} onClick={() => navigate(`/Category Product`)}>
    
    <h2 className={styles.heading}>Girl</h2>

    </div>
    <div className={styles.box5} onClick={() => navigate(`/Category Product`)}>

    <h2 className={styles.heading}>Boy</h2>
   
    </div>
    



    </div>
  
</div>
  )
}

export default Category
