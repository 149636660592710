import axios from "axios";
import FormData from "form-data";

const getToken = async () => {
  try {
    const token = localStorage.getItem("token");
    return token;
  } catch (error) {
    return null;
  }
};

class Api {
  constructor() {
    this.client = null;
    this.api_url =
      process.env.NODE_ENV === "development"
        ? "https://misseymoppet.com/api"
        : "https://misseymoppet.com/api";
    //  this.api_url = process.env.NODE_ENV === "development" ? "http://localhost:5000/api" : "http://localhost:5000/api";
  }

  init = (type) => {
    let headers;

    headers = {
      Accept: "application/json",
    };

    if (type === "multipart/form-data") {
      headers = {
        "Content-Type": "multipart/form-data",
      };
    }

    this.client = axios.create({
      baseURL: this.api_url,
      timeout: 31000,
      headers: headers,
    });

    this.client.interceptors.request.use(
      async (config) => {
        const token = await getToken();

        config.headers["Authorization"] = `Bearer ${token}`;
        return config;
      },
      (error) => {
        throw error;
      }
    );

    return this.client;
  };


  // subscribe for outofstock products

  subscribeToOutofstockProducts = (productId) => {
    return this.init().post(`/user/dashbioard/subscribe-to-outofstock-products`,productId)
  }

  // currency converter

  currencyConverter = () => {
    return this.init().get('/user/dashboard/currency-convert')
  }

  // get pincode servicability

  getPincodeValidation = (data) => {
    return this.init().get(`/user/dashboard/get-pincode-validation?pinCode=${data}`)
  }

  // products
  fetchProducts = (data) => {
    return this.init().get(`/user/dashboard/get-products-by-category?${data}`);
  };

  getProductById = (productId) => {
    return this.init().get(`/user/dashboard/get-single-product/${productId}`)
  }

  getSimilarProducts = (productId) => {
    return this.init().get(`/user/dashboard/get-similar-products/${productId}`)
  }

  searchOrFilterProduct = (queryParams) => {
    return this.init().get(`/user/dashboard/searchOrFilterProducts?${queryParams}`)
  }

  addViewCount = (productId) => {
    return this.init().put(`/user/dashboard/inc-count-views/${productId}`)
  }

  sortProducts = (sortBy) => {
    return this.init().get(`/user/dashboard/sort-products?sortBy=${sortBy}`)
  }

  getAllReviews = (productId) => {
    return this.init().get(`/user/dashboard/get-product-review/${productId}`)
  }

  contactUs = (body) => {
    return this.init().post("/user/profile/contact-us",body)
  }

  getFilterCounts = () => {
    return this.init().get(`/user/dashboard/get-count-of-filters`)
  }



  // cart
  getCart = () => {
    return this.init().get(`/user/cart/get-cart-items`);
  };
  getProfile = () => {
    return this.init().get(`/user/profile/get-profile`);
  };
  editProfile = (data) => {
    return this.init().put(`/user/profile/edit-user`,data);
  };
  sendAddress = (data) => {
    return this.init().post(`/user/profile/create-new-address`);
  };

  removeProductFromCart = (body) => {
    return this.init().delete(`/user/cart/delete-from-cart/${body?.item?._id}`, {
        data: { color: body?.color, size: body?.size }  
    });
};

  addProductToCart = (body) => {
    return this.init().post(`/user/cart/add-to-cart`, body);
  };

  //user auth
  signUpWithPhone = (body) => {
    return this.init().post(`/user/onboard/sign-up-phone`,body)
  }

  verifySignUpWithOtp = (body) => {
    return this.init().post(`/user/onboard/verify/sign-up-phone`,body)
  }

  resendOtpForSignUp = (body) => {
    return this.init().post(`/user/onboard/resend-otp`,body)
  }

loginWithPhone = (body) => {
  return this.init().post("/user/auth/login/phone",body)
}

verifyLoginWithPhone = (body) => {
  return this.init().post("/user/auth/login/verify-phone",body)
}

resendOtpForLogin = (body) => {
  return this.init().post(`/user/auth/resend-otp`,body)
}

signUpWithGoogle = (body) => {
  return this.init().post("/user/onboard/sign-up-google",body)
}

loginWithGoogle = (body) => {
  return this.init().post("/user/auth/login/google",body)
}

updateProfileDetails = (body) => {
  return this.init().post("/user/profile/update-profile-details",body)
}


//order
paymentStepOne = (body) => {
  return this.init().post(`/user/order/payment-step-one`,body)
}

paymentStepTwo = (body) => {
  return this.init().post(`/user/order/create-new-order`,body)
}

getLastOrder = (body) => {
  return this.init().get(`/user/order/get-last-order`)
}

getOrderHistory = (page) => {
  return this.init().get(`user/order/get-order-history?page=${page}`)
}

getOrderDetails = (orderId) => {
  return this.init().get(`/user/order/get-single-order/${orderId}`)
}

addReviewForProduct = (body) => {
  return this.init().post(`/user/order/add-review/${body?.orderId}/${body?.productId}`, {
    rating: body?.rating,
    comment: body?.comment,
    images: body?.images,
  });
};

getAllCustomerReviews = (orderId) => {
  return this.init().get(`/admin/order/get-all-reviews/${orderId}`)
}

exchangeRequest = (orderId,formData) => {
  return this.init().post(`/user/exchange/request-exchange/${orderId}`,
    formData
  )
}

fileUpload = (data) => {
  return this.init('multipart/form-data').post("/admin/upload-file", data)
}
getInvoice = (orderId) => {
  return this.init().post(`/user/order/get-invoice/${orderId}`)
}


//address
addNewAddress = (body) => {
  return this.init().post('/user/profile/create-new-address',body)
}

getAllAddress = () => {
  return this.init().get('/user/profile/get-all-address')
}

deleteAddress = (addressId) => {
  return this.init().delete(`/user/profile/delete-address/${addressId}`)
}

editAddress = (addressId,body) => {
  return this.init().put(`/user/profile/edit-address/${addressId}`,body)
}



//wishlist

addToWishlist = (body) => {
  return this.init().post(`/user/wishlist/add-to-wishlist`,body)
}

getWishlist = () => {
  return this.init().get(`/user/wishlist/get-wishlist-items`)
}

deleteFromWishlist = (body) => {
  return this.init().delete(`/user/wishlist/delete-from-wishlist/${body?.item?._id}`,{
    data: { color: body?.color, size: body?.size }  
  })
}

moveFromWishlistToCart = (productId) => {
  return this.init().put(`/user/wishlist/move-wishlistitem-to-cart/${productId}`)
}



deleteAllItemsFromWishlist = () => {
  return this.init().put(`/user/wishlist/delete-all-data-from-wishlist`)
}

moveAllFromWishlistToCart = () => {
  return this.init().put(`/user/wishlist/move-all-data-to-cart`)
}


}



const api = new Api();

export default api;
