import React from 'react'
import styles from "../components/errorpage/errorpage.module.css";
import { useNavigate } from 'react-router-dom';

const EmptyPage = ({content , button}) => {
    const nav = useNavigate()
    return (


        <div className={styles.contactCol2}>
            <img width={300} src='/assets/images/emptyCart.png' />

            {content ? (<h2>{content}</h2>) : (<h2>Oops! Products not found</h2>)}
            
            <div className={styles.buttonGroup}>
                <button type="submit" className={styles.saveButton} onClick={() =>{ 
                    nav(`/Category Product`)
                    window.location.reload()
                    }}>
                   {button ?  (<div>{button}</div>) : ( "Add Products")}
                </button>
            </div>

        </div>
    )
}

export default EmptyPage