import React, { useEffect, useState } from "react";
import styles from "./cartpageDashboard.module.css";
import { useSelector, useDispatch } from "react-redux";
import {
  getCartProducts,
  addProductToCart,
  removeFromCart,
} from "../../redux/reducers/cartSlice";
import { useNavigate } from "react-router-dom";
import { Bin } from "../../Svg";
import { Box, CircularProgress } from "@mui/material";
import { getAllAddress } from "../../redux/reducers/addressSlice";
import { getPincodeValidation } from "../../redux/reducers/productSlice";


import EmptyPage from "../emptyPage";

const CartPageDashbord = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { cartData, isLoading } = useSelector((state) => state.cart);

  useEffect(() => {
    dispatch(getCartProducts());
  }, [dispatch]);

  useEffect(() => {
    dispatch(getAllAddress());
  }, [dispatch]);

  const { addressData } = useSelector((state) => state.address);




  useEffect(() => {

    const defaultAddress = addressData?.find((address) => address.isDefault)

    if(defaultAddress){
   
      dispatch(getPincodeValidation(defaultAddress?.zip))
    }
    else{
   
      dispatch(getPincodeValidation(addressData[0]?.zip))
    }

  },[addressData])


  const {  pinCodeData, } = useSelector(
    (state) => state.products
  );



  const rate = parseFloat(localStorage.getItem("rate")) || 1;
  const currency = localStorage.getItem("currency") || "INR";
  const currencySymbol = currency === "USD" ? "$" : "₹";

  const formatPrice = (price) => {
    return (price * rate).toFixed(2);
  };

  const incrementCount = (item) => {
    const body = { productId: item?.item?._id, count: 1, countInc: true, size: item?.size, color: item?.color };
    dispatch(addProductToCart(body));
  };

  const decrementCount = (item) => {
    const body = { productId: item?.item?._id, count: 1, size: item?.size, color: item?.color };
    dispatch(addProductToCart(body));
  };

  const handleRemoveItem = (item) => {
    dispatch(removeFromCart(item));
  };



  const saleTotal = cartData?.reduce((acc, item) => {
    const matchingVariation = item?.item?.variationInventory?.find(
      (variant) => variant.color === item.color && variant.size === item.size
    );
  
    const additionalPrice = matchingVariation?.additionalPrice || 0;
    const salePrice = item?.item?.salePrice || 0;
    const count = item?.count || 0;
  
    // Skip products with quantity 0
    if (!matchingVariation || matchingVariation.quantity === 0) {
      return acc;
    }
  
    return acc + (salePrice + additionalPrice) * count;
  }, 0);
  
  const baseTotal = cartData?.reduce((acc, item) => {
    const matchingVariation = item?.item?.variationInventory?.find(
      (variant) => variant.color === item.color && variant.size === item.size
    );
  
    const additionalPrice = matchingVariation?.additionalPrice || 0;
    const basePrice = item?.item?.basePrice || 0;
    const count = item?.count || 0;
  
    // Skip products with quantity 0
    if (!matchingVariation || matchingVariation.quantity === 0) {
      return acc;
    }
  
    return acc + (basePrice + additionalPrice) * count;
  }, 0);
  

  return (
    <div className={styles.Container}>
      {isLoading ? (
        <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
          <CircularProgress />
        </Box>
      ) : cartData?.length > 0 ? (
        <div className={styles.cartContainer}>
          {/* Left Side - Cart Items */}
          <div className={styles.cartItems}>
            <table className={styles.cartTable}>
              <thead>
                <tr>
                  <th className={styles.heading}>Image</th>
                  <th className={styles.heading}>Product</th>
                  <th className={styles.heading}>Quantity</th>
                  <th className={styles.heading}>Price</th>
                  <th className={styles.heading}>Total</th>
                  <th className={styles.heading}></th>
                </tr>
              </thead>
              <tbody>
                           {cartData?.map((item, index) => {
  // Find the matching variation inventory
  const matchingVariant = item?.item?.variationInventory?.find(
    (variant) =>
      variant.color === item.color && variant.size === item.size
  );

  // Check if the product is out of stock
  const isOutOfStock = matchingVariant?.quantity === 0;

  return (
    <tr
      key={index}
      className={`${styles.productDetailes} ${
        isOutOfStock ? styles.outOfStockRow : ""
      }`}
      style={{
        opacity: isOutOfStock ? 0.5 : 1,
      }}
    >
      <td>
        <img
          src={item?.item?.featuredImage}
          alt={item?.item?.name || "Product Image"}
          className={styles.productImage}
          onClick={() => navigate(`/Category Product/Product details/${item?.item?._id}`)}

        />
      </td>
      <td>
        <div className={styles.head1}>{item?.item?.name}</div>
        <p className={styles.variation}>
          {item?.color}, {item?.size}{" "}
          {(() => {
            if (!matchingVariant || matchingVariant.quantity === undefined) {
              return null; // Handles cases where no matching variant is found
            }
            return matchingVariant.quantity === 0 ? (
              <p style={{ color: "red" }}>Out of stock</p>
            ) : matchingVariant.quantity < 5 ? (
              <p style={{ color: "orange" }}>
                Only {matchingVariant.quantity} left
              </p>
            ) : null;
          })()}
        </p>
      </td>
      <td className={styles.head}>
        <div className={styles.quantityControls}>
          <div className={styles.Controls} style={{ pointerEvents: isOutOfStock ? "none" : "all",
}}>
            <button
              className={styles.opreter}
              onClick={() => decrementCount(item)}
            >
              -
            </button>
            <span className={styles.num}>{item?.count || 1}</span>
            <button
              className={styles.opreter}
              onClick={() => incrementCount(item)}
            >
              +
            </button>
          </div>
        </div>
      </td>
      <td className={styles.head}>
        {currencySymbol}{" "}
        {(
          ((item?.item?.salePrice || 0) +
            (item?.item?.variationInventory?.find(
              (variant) =>
                variant.color === item.color && variant.size === item.size
            )?.additionalPrice || 0)) *
          rate
        ).toFixed(2)}
      </td>
      <td className={styles.head}>
        {currencySymbol}{" "}
        {(
          ((item?.item?.salePrice || 0) +
            (item?.item?.variationInventory?.find(
              (variant) =>
                variant.color === item.color && variant.size === item.size
            )?.additionalPrice || 0)) *
          (item?.count || 1) *
          rate
        ).toFixed(2)}
      </td>
      <td>
        <button
          className={styles.removeButton}
          onClick={() => handleRemoveItem(item)}
        >
          <Bin />
        </button>
      </td>
    </tr>
  );
})}
              </tbody>
            </table>
           
            <div className={styles.addMoreItems} >
              <span className={styles.more} onClick={()=>navigate(`/Category Product`)}>
                Add More Items
              </span>
            </div>
          </div>

          {/* Right Side - Order Summary */}
          <div className={styles.orderSummary}>
            <h2 className={styles.Order}>Order Summary</h2>
            <div className={styles.summaryItem}>
              <span className={styles.priceSummary}>Price</span>
              <span className={styles.priceSummary}>{currencySymbol} {formatPrice(baseTotal)}</span>
            </div>
            <div className={styles.summaryItem}>
              <span className={styles.priceSummary}>Discount</span>
              <span className={styles.priceSummary}>{currencySymbol} {formatPrice(baseTotal - saleTotal)}</span>
            </div>
            <div className={styles.summaryItem}>
              <span className={styles.priceSummary}>Shipping</span>
              <span className={styles.FreeSummary}>Free</span>
            </div>
            <hr />
            <div className={styles.summaryTotal}>
              <span className={styles.priceSummary}>Total</span>
              <span className={styles.bold}>{currencySymbol} {formatPrice(saleTotal)}</span>
            </div>
            <div className={styles.summaryItem}>
              <span className={styles.priceSummary}>Estimated Delivery by</span>
              {pinCodeData?.data ? (
            <span className={styles.bold}>{pinCodeData?.data?.etd}</span>
          ) : (
            <span style={{color:'blue',fontSize:'12px',display:"flex",alignItems:"center",cursor:"pointer"}} onClick={() => navigate("/addaddress")}>Please provide deliverable pincode</span>
          )}            </div>
            <button
              className={styles.checkoutButton}
              onClick={() => cartData.length > 0 && navigate("/checkout")}
              disabled={cartData.length === 0}
            >
              Proceed to Checkout
            </button>
          </div>
        </div>
      ) : (
        <EmptyPage />
      )}
       {cartData?.length > 0 ? (
            <div className={styles.mobilecontainer}>
              <h2
                className={styles.mobileheading}
                onClick={() => navigate(`/Category Product`)}
              >
                Add More Items
              </h2>
              <div className={styles.mobilecontant1}>
              {cartData?.map((item, index) => {
  const matchingVariant = item?.item?.variationInventory?.find(
    (variant) => variant.color === item.color && variant.size === item.size
  );

  const isOutOfStock = !matchingVariant || matchingVariant.quantity === 0;

  return (
    <div
      key={index}
      className={styles.mobilecontant}
      style={{
        opacity: isOutOfStock ? 0.5 : 1, // Reduce opacity if out of stock
      
      }}
    >
      <div className={styles.mobilebox1}>
        <img
          src={item?.item?.featuredImage}
          alt={item?.item?.name || "Product Image"}
          className={styles.productImage}
          onClick={() => navigate(`/Category Product/Product details/${item?.item?._id}`)}

        />
        
        <div className={styles.rast}>
          <button
            className={styles.removeButton}
            onClick={() => handleRemoveItem(item)}
          >
            <Bin />
          </button>
          <h5 className={styles.head1}>{item?.item?.name}</h5>
        </div>
      </div>
      <div className={styles.mobilebox}>
        <p className={styles.heading}>Price</p>
        <p className={styles.head}> {currencySymbol}{" "}
        {(
          ((item?.item?.salePrice || 0) +
            (item?.item?.variationInventory?.find(
              (variant) =>
                variant.color === item.color && variant.size === item.size
            )?.additionalPrice || 0)) *
          rate
        ).toFixed(2)}</p>
      </div>
      <div className={styles.mobilebox}>
        <p className={styles.heading}>Quantity</p>
        <div className={styles.quantityControls}>
          <div className={styles.Controls} style={{ pointerEvents: isOutOfStock ? "none" : "all",}}>
            <button
              className={styles.opreter}
              onClick={() => decrementCount(item)}
              disabled={isOutOfStock} // Disable button if out of stock
            >
              -
            </button>
            <span className={styles.num}>{item.count}</span>
            <button
              className={styles.opreter}
              onClick={() => incrementCount(item)}
              disabled={isOutOfStock} // Disable button if out of stock
            >
              +
            </button>
          </div>
        </div>
      </div>
      <div className={styles.mobilebox}>
        <p className={styles.heading}>Total</p>
        <p className={styles.head}>
        {currencySymbol}{" "}
        {(
          ((item?.item?.salePrice || 0) +
            (item?.item?.variationInventory?.find(
              (variant) =>
                variant.color === item.color && variant.size === item.size
            )?.additionalPrice || 0)) *
          (item?.count || 1) *
          rate
        ).toFixed(2)}
        </p>
        
      </div>
      <p className={styles.variation}>
          {item?.color}, {item?.size}{" "}
          {(() => {
            if (!matchingVariant || matchingVariant.quantity === undefined) {
              return null; // Handles cases where no matching variant is found
            }
            return matchingVariant.quantity === 0 ? (
              <p style={{ color: "red" }}>Out of stock</p>
            ) : matchingVariant.quantity < 5 ? (
              <p style={{ color: "orange" }}>
                Only {matchingVariant.quantity} left
              </p>
            ) : null;
          })()}
        </p>
    </div>
  );
})}

              </div>

              <div className={styles.orderSummary}>
                <h2 className={styles.Order}>Order Summary</h2>
                <div className={styles.summaryItem}>
                  <span className={styles.priceSummary}>Price</span>
                  <span className={styles.priceSummary}>
                  {currencySymbol} {baseTotal.toFixed(2)}
                  </span>
                </div>
                <div className={styles.summaryItem}>
                  <span className={styles.priceSummary}>Discount</span>
                  <span className={styles.priceSummary}>
                  {currencySymbol} {(baseTotal - saleTotal).toFixed(2)}
                  </span>
                </div>
                <div className={styles.summaryItem}>
                  <span className={styles.priceSummary}>Shipping</span>
                  <span className={styles.FreeSummary}>Free</span>
                </div>
                <hr />
                <div className={styles.summaryTotal}>
                  <span className={styles.priceSummary}>Total</span>
                  <span className={styles.bold}> {currencySymbol} {saleTotal.toFixed(2)}</span>
                </div>
                <div className={styles.summaryItem}>
                  <p>Estimated Delivery by:</p>
                  {pinCodeData?.data ? (
                    <span className={styles.bold}>
                      {pinCodeData?.data?.etd}
                    </span>
                  ) : (
                    <span
                      style={{
                        color: "blue",
                        fontSize: "12px",
                        display: "flex",
                        alignItems: "center",
                        cursor: "pointer",
                      }}
                      onClick={() => navigate("/addaddress")}
                    >
                      Please provide deliverable pincode
                    </span>
                  )}
                </div>
                <button
                  className={styles.checkoutButton}
                  onClick={() => cartData.length > 0 && navigate("/checkout")}
                  disabled={cartData.length === 0}
                >
                  Proceed to Checkout
                </button>
              </div>
            </div>
          ) : null}
    </div>
  );
};

export default CartPageDashbord;
