import React, { useEffect } from 'react'
import styles from "./home.module.css"
import AddSliders from '../../smallcomponents/addsliders/AddSliders'
import OurPinky from '../../smallcomponents/ourPinky/OurPinky'
import Dress from '../../smallcomponents/dress/Dress'
import EarthFriendly from '../../smallcomponents/earthfriendly/EarthFriendly'
import Category from '../../smallcomponents/category/Category'
import Clothes from '../../smallcomponents/clothes/Clothes'
import Testimonials from "../../smallcomponents/testimonials/Testimonials"
import { Link,useNavigate } from 'react-router-dom'
import ShapByCategory from '../../smallcomponents/shapbycategary/ShapByCategory'
import Occasional from '../../smallcomponents/occasional/Occasional'


const Home = () => {

const navigate = useNavigate()


  return (
    < div className={styles.fullhome}>
    <div className={styles.container}>
         <div className={styles.section}>
         <div className={styles.box}>
         <div className={styles.left}>
<h1 className={styles.heading}>Perfect <br/>Kids Collection</h1>
<p className={styles.text}>New Spring drops from Over. Shop the Collection</p>
<Link to="/Category Product ">
<button className={styles.hero_button}>Shop Now</button></Link>
         </div>

         <div className={styles.right}>
         <div className={styles.Wh_icon}>
         <a href="https://wa.me/+919148884999">
          <img src="./assets/images/wh.png" alt="WhatsApp Icon" />
        </a>
        </div>
        </div>

         </div>

         </div>

    </div>
    <AddSliders/>
    <OurPinky/>
    <Dress/>
    <EarthFriendly/>
    <Category/>
    <ShapByCategory/>
  <Occasional/>
    <Clothes/>
    <Testimonials/>
    </div>
  )
}

export default Home