import React from 'react'
import styles from "./clothes.module.css"
import { useNavigate } from 'react-router-dom'

const Clothes = () => {

  const navigate = useNavigate()
  return (
<div className={styles.container}>
<div className={styles.section}>
<div className={styles.left}>
<div className={styles.maincontainer}>
<img src={require('../../assets/images/clothes12.png')} className={styles.mainImg_1} />
          </div>
</div>
<div className={styles.right}>
    <h2 className={styles.heading}>Clothes That Cherish Growth</h2>
    <p className={styles.head}>Little Moments, Big Memories, Yet Clothes Barely Worn Left Behind. Imagine If Their Favorite Dress Grew With Their Memories!
    </p>
    <p className={styles.heads}>Introducing Outfits Designed To Grow With Your Child! Comfort, Sustainability, Love!</p>
    <button className={styles.button_2} onClick={() => navigate(`/Category Product`)}>Shop Our Products</button>
</div>

</div>

    </div>
  )
}

export default Clothes