import React, { useState, useEffect } from "react";
import styles from "./headerTop.module.css";
import ReactCountryFlag from "react-country-flag";
import { IoIosArrowDown } from "react-icons/io";
import { Iconq11, Iconq12, Iconq13 } from "../../Svg";
import api from "../../utils/Api";
import toast from "../../utils/toast";

const HeaderTop = () => {

  const country =  localStorage.getItem("currency") || "INR"
  
  const [selectedCountry, setSelectedCountry] = useState(country);
  const [conversionRate, setConversionRate] = useState(
    parseFloat(localStorage.getItem("rate")) || 1
  );

  const countries = [
    { value: "USD", label: " $ USD", code: "US" },
    { value: "INR", label: " ₹ INR", code: "IN" },
  ];

  const fetchCurrencyRate = async (value) => {
    try {
        const { data, status } = await api.currencyConverter();
        const rate = data?.data?.data?.rate || 1;

        if (value === "USD") {
            setConversionRate(rate);
            localStorage.setItem("currency", "USD");
            localStorage.setItem("rate", rate);
        } else {
            setConversionRate(1);
            localStorage.setItem("currency", "INR");
            localStorage.removeItem("rate");
        }
    } catch (error) {
        toast.error("Something went wrong");
        console.log("Error fetching currency rate:", error);
    }
};

const handleChange = async (value) => {
    setSelectedCountry(value);
    localStorage.setItem("currency", value);
    await fetchCurrencyRate(value);

    // Reload after setting currency in localStorage and updating the rate
    window.location.reload();
};

  return (
    // <div className={styles.container}>
    //   <div className={styles.section}>
    //     <div className={styles.left}>
    //     </div>
    //     <div className={styles.right}>
    //       <div className={styles.dropdownContainer}>
    //         <div className={styles.customSelect}>
    //           <div className={styles.selectedOption}>
    //             <ReactCountryFlag
    //               countryCode={
    //                 countries.find((c) => c.value === selectedCountry)?.code
    //               }
    //               svg
    //               style={{ marginRight: "8px" }}
    //             />
    //             {countries.find((c) => c.value === selectedCountry)?.label}
    //             <IoIosArrowDown style={{ marginLeft: "8px", fontSize: "16px" }} />
    //           </div>
    //           <ul className={styles.optionsList}>
    //             {countries.map((country) => (
    //               <li
    //                 key={country.value}
    //                 onClick={() => handleChange(country.value)}
    //                 className={`${styles.option} ${
    //                   country.value === selectedCountry ? styles.selected : ""
    //                 }`}
    //               >
    //                 <ReactCountryFlag
    //                   countryCode={country.code}
    //                   svg
    //                   style={{ marginRight: "8px" }}
    //                 />
    //                 {country.label}
    //               </li>
    //             ))}
    //           </ul>
    //         </div>
    //       </div>
    //  </div>
    //   </div>
    // </div>
    <></>
  );
};

export default HeaderTop;
