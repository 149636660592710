import React from 'react'
import styles from "./sapByCategory.module.css"
import { useNavigate } from 'react-router-dom'

const ShapByCategory = () => {
    const navigate = useNavigate()
  return (
    <div className={styles.container}>
   
    <div className={styles.wrapper}>
              <div className={styles.slider}>
          <div className={styles.slidetrack}>
            
          <div className={styles.box1}>
   
   <h2 className={styles.title}>Shop by <br/>
   category</h2>
   <button className={styles.button_3} onClick={() => navigate(`/Category Product`)}>Shop Now</button>

   </div>
   <div className={styles.box2}onClick={() => navigate(`/Category Product`)}>

   <h2 className={styles.heading}>Baby</h2>
 
   </div>
   <div className={styles.box3}onClick={() => navigate(`/Category Product`)}>

   <h2 className={styles.heading}>Toddler</h2>
   
   </div>
   <div className={styles.box4}onClick={() => navigate(`/Category Product`)}>
   
   <h2 className={styles.heading}>Girl</h2>

   </div>
   <div className={styles.box5}onClick={() => navigate(`/Category Product`)}>

   <h2 className={styles.heading}>Boy</h2>
  
   </div>
   <div className={styles.box1} >
   
   <h2 className={styles.title}>Shop By <br/>
   Category</h2>
   <button className={styles.button_3} onClick={() => navigate(`/Category Product`)}>Shop Now</button>

   </div>
   <div className={styles.box2}onClick={() => navigate(`/Category Product`)}>

   <h2 className={styles.heading}>Baby</h2>
 
   </div>
   <div className={styles.box3}onClick={() => navigate(`/Category Product`)}>

   <h2 className={styles.heading}>Toddler</h2>
   
   </div>
   <div className={styles.box4}onClick={() => navigate(`/Category Product`)}>
   
   <h2 className={styles.heading}>Girl</h2>

   </div>
   <div className={styles.box5}onClick={() => navigate(`/Category Product`)}>

   <h2 className={styles.heading}>BOY</h2>
  
   </div>
   <div className={styles.box1}>
   
   <h2 className={styles.title}>Shop By <br/>
   Category</h2>
   <button className={styles.button_3} onClick={() => navigate(`/Category Product`)}>Shop Now</button>

   </div>
   <div className={styles.box2}onClick={() => navigate(`/Category Product`)}>

   <h2 className={styles.heading}>Baby</h2>
 
   </div>
   <div className={styles.box3}onClick={() => navigate(`/Category Product`)}>

   <h2 className={styles.heading}>Toddler</h2>
   
   </div>
   <div className={styles.box4}onClick={() => navigate(`/Category Product`)}>
   
   <h2 className={styles.heading}>Girl</h2>

   </div>
   <div className={styles.box5}>

   <h2 className={styles.heading}>BOY</h2>
  
   </div>
   <div className={styles.box1}>
   
   <h2 className={styles.title}>Shop By <br/>
   Category</h2>
   <button className={styles.button_3} onClick={() => navigate(`/Category Product`)}>Shop Now</button>

   </div>
   <div className={styles.box2}>

   <h2 className={styles.heading}>Baby</h2>
 
   </div>
   <div className={styles.box3}>

   <h2 className={styles.heading}>Toddler</h2>
   
   </div>
   <div className={styles.box4}>
   
   <h2 className={styles.heading}>Girl</h2>

   </div>
   <div className={styles.box5}>

   <h2 className={styles.heading}>BOY</h2>
  
   </div>
       </div>
      </div>
    </div>
  </div>

  )
}

export default ShapByCategory