import React, { useState } from 'react';
import styles from './reviewpopup.module.css';
import { Star } from '../../Svg'; // Assuming you're using this somewhere
import { addReviewForProduct, getAllCustomerReviews } from '../../redux/reducers/orderSlice'; // Adjust your import as needed
import { useDispatch } from 'react-redux';
import StarRatings from "react-star-ratings";

const ReviewPopup = ({ data, onClose, orderId }) => {
  const dispatch = useDispatch();

  // State to handle review text and rating
  const [reviewText, setReviewText] = useState('');
  const [rating, setRating] = useState(0);

  const handleRatingChange = (newRating) => {
    setRating(newRating);
  };

  const handleSubmitReview = () => {
    if (!reviewText.trim()) {
      alert("Review text cannot be empty");
      return;
    }

    const body = {
      orderId,
      productId: data?.productId?._id, 
      rating,
      comment: reviewText,
    };

    dispatch(addReviewForProduct(body));
    onClose(); 
  };

  return (
    <div className={styles.blur_background}>
      <div className={styles.popup_card}>
        <div className={styles.popup_head}>
          <div>
            <span>Write a review</span>
          </div>
          <div>
            <button className={styles.close_btn} onClick={onClose}>
              &times;
            </button>
          </div>
        </div>
        <div>
          <p style={{ color: '#7E7E7E' }}>Add your rating and reviews here</p>
        </div>
        <div className={styles.reviewprod}>
          <div>
            <img width={60} height={60} src={data?.productId?.featuredImage} alt="product" />
          </div>
          <div>{data?.productId?.name}</div>
        </div>
        <div className={styles.reviewicon}>
          <div>Rate this Product</div>
          <div style={{ textAlign: 'center' }}>
            <StarRatings
              rating={rating}
              starRatedColor="gold"
              numberOfStars={5}
              starDimension="35px"
              starSpacing="5px"
              name="rating"
              starHoverColor={"gold"}
              changeRating={handleRatingChange} 

            />
          </div>
        </div>
        <div className={styles.reviewSection}>
          <label htmlFor="review">Write Your Review</label>
          <textarea
            id="review"
            className={styles.reviewtextarea}
            placeholder="Your review here..."
            value={reviewText}
            onChange={(e) => setReviewText(e.target.value)}
          />
        </div>
        <div className={styles.orderbuttons}>
          <button className={styles.trackOrderBtn} onClick={onClose}>Cancel</button>
          <button className={styles.invoiceBtn} onClick={handleSubmitReview}>Submit</button>
        </div>
      </div>
    </div>
  );
};

export default ReviewPopup;
