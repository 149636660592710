import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Arrow1, BradeArrow } from "../../Svg";
import styles from "./breadcrumbs.module.css";

const Breadcrumbs = () => {
  const navigate = useNavigate();
  const [isMobile, setIsMobile] = useState(false);
  const restrictedList = ["orderHistory", "Cartpage", "wishlist", "myaddress",];
  


  const goBack = () => {
    navigate(-1); // Go back to the previous page
  };
  const path = useLocation();
  const location = decodeURIComponent(path.pathname);
  console.log("location", location);
  const pathnames = location.split("/").filter((x) => x);
  const intialPath = location.split("/")[1];

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 768) {
        setIsMobile(true);
      } else {
        setIsMobile(false);
      }
    };

    window.addEventListener("resize", handleResize);

    // Cleanup listener on component unmount
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
<div
  className={styles.breadcrumbs}
  style={{
    display:
      isMobile && restrictedList.includes(intialPath) ? "none" : "flex",
  }}
>
  {" "}
  {/* Use the imported styles */}
  <div className={styles.arrow} onClick={goBack}>
    <Arrow1 />{" "}
  </div>
  <Link to="/" className={styles.homeLink}>
    Home
  </Link>
  {pathnames.map((value, index) => {
    const last = index === pathnames.length - 1; 
    const secondLast =
      index === pathnames.length - 2 && pathnames[pathnames.length - 2] === "Product details"; 
    const to = `/${pathnames.slice(0, index + 1).join("/")}`;

   
    if (last && pathnames[pathnames.length - 2] === "Product details") {
      return null;
    }

    if (last) {
      // Non-clickable last breadcrumb
      return (
        <span
          key={to}
          className={styles.breadcrumbItem}
          style={{ color: "#C86550" }}
        >
          <BradeArrow /> {value}
        </span>
      );
    }

    if (secondLast) {
      // Non-clickable second-to-last breadcrumb
      return (
        <span
          key={to}
          className={styles.breadcrumbItem}
          style={{ color: "#C86550" }}
        >
          <BradeArrow /> {value}
        </span>
      );
    }

    // Clickable breadcrumb for all other cases
    return (
      <span key={to} className={styles.breadcrumbItem}>
        <BradeArrow />{" "}
        <Link
          to={to}
          className={styles.breadcrumbLink}
          style={{
            color: "#7E7E7E",
          }}
        >
          {value}
        </Link>
      </span>
    );
  })}
</div>

  );
};

export default Breadcrumbs;
